import { UserContextService } from '../service/user-context.service';
import { AppRouterService } from '../service/app-router.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { QuizType } from '../enums/quiz-type';

@Injectable()
export class QuizGuard {
    constructor(
        private userContextService: UserContextService,
        private router: AppRouterService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const quizType = route.params['type'] as QuizType;

        if (!this.userContextService.isAuthorize.value) {
            this.router.openLogin(window.location.href);
            return false;
        }

        if (quizType === QuizType.Leadership && !this.userContextService.isMarketLeader()) {
            this.router.openAccessDeniedPage();
            return false;
        }

        return true;
    }

    public canActivateChild(route: ActivatedRouteSnapshot): boolean {
        return this.canActivate(route);
    }
}