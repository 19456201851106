import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgStyle } from '@angular/common';
import { links } from 'src/app/core/links';
import { NavTabEnum } from 'src/app/core/enums/nav-tab.enum';
import { UserRoleEnum } from 'src/app/core/enums/user-role.enums';
import { UserContextService } from 'src/app/core/service/user-context.service';
import { environment } from 'src/environments/environment';
import { ArrayUtil } from 'src/app/core/utility/array.util';

@Component({
    selector: 'navigation-mobile-panel',
    templateUrl: './navigation-mobile-panel.component.html',
    styleUrls: ['./navigation-mobile-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, RouterLink, NgStyle]
})

export class NavigationMobilePanelComponent {
    public reportOpen = false;
    public actionOpen = false;
    public adminSettingsOpen = false;
    public links = links;
    public role: UserRoleEnum;
    public documentLink = { name: 'Documents', href: links.documentsLink + '/' + links.documents.List };
    public actionLinks: ExtendedNavLink[] = [];
    public reportLinks: ExtendedNavLink[] = [];
    public adminSettings: ExtendedNavLink[] = null;
    public userName: string;
    public tabInFocus: NavTabEnum = 0;
    public enum = NavTabEnum;
    public currentPage: string;
    public isAdmin: boolean;
    public isCfo: boolean;
    public adminUrl = links.adminSettingsLink + '/' + links.adminSettings.mainPage;
    public isNumber = (value: any) => !Number.isNaN(Number(value));

    constructor(private context: UserContextService) { }

    public ngOnInit(): void {
        this.isAdmin = this.context.isAdmin();
        this.isCfo = this.context.isCfo();
        this.setLinks();
    }

    public onLinkClick(): void {
        document.getElementById('mainMenu').click();
    }

    public onReportClick(link: ExtendedNavLink): void {
        link.isSelected = !link.isSelected;
    }

    public onFolderClick(current: NavTabEnum): void {
        window.scrollTo(0, 0);
        if (this.tabInFocus === current) {
            this.tabInFocus = 0;
            return;
        }
        this.tabInFocus = current;
    }

    private setLinks(): void {
        const roles = this.context.getRoles();
        if (this.context.isAccounting()) {
            this.setAccountingLinks();
            return;
        }
        if (this.context.isAdmin()) {
            this.setAdminLinks();
            return;
        }
        roles.forEach(role => {
            if (role == UserRoleEnum.ea) {
                this.setEaLinks();
            }
            if (role == UserRoleEnum.sm) {
                this.setSmLinks();
            }
            if (role == UserRoleEnum.rep) {
                this.setRepLinks();
            }
            if (role == UserRoleEnum.alterationApprover) {
                this.reportLinks.push({ name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport });
            }
            if (role == UserRoleEnum.expenseApprover) {
                this.reportLinks.push({ name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport });
            }
            if (role == UserRoleEnum.expenseSubmiter) {
                this.reportLinks.push({ name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport });
            }
        });

        if (this.actionLinks.length == 0) {
            this.actionLinks = null;
        }

        this.reportLinks = ArrayUtil.distinct(this.reportLinks, 'name');
        this.actionLinks = ArrayUtil.distinct(this.actionLinks, 'name');
    }

    private setAdminLinks(): void {
        this.reportLinks = [
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
            { name: 'My Items Report', href: links.reportLink + '/' + links.reports.itemsReport },
            { name: 'My Cashflow Report', href: links.reportLink + '/' + links.reports.cashflowReport },
            { name: 'TotalNCPBank Report', href: links.reportLink + '/' + links.reports.totalNCPBankReport },
            { name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport },
            { name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport },
            { name: 'My Payments Report', href: links.reportLink + '/' + links.reports.paymentReport },
            { name: 'Rep Overdue Report', href: links.reportLink + '/' + links.reports.overdueReport },
            { name: 'Management Report', href: links.reportLink + '/' + links.reports.managementReport },
            { name: 'Rep Overview Report', href: links.reportLink + '/' + links.reports.overviewReport },
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Top Clients Report', href: links.reportLink + '/' + links.reports.topClientReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport },
            { name: 'Top Service Report', href: links.reportLink + '/' + links.reports.topServiceReport },
            { name: 'Remake Item Report', href: links.reportLink + '/' + links.reports.remakeItemReport },
            { name: 'Profit Loss Report', href: links.reportLink + '/' + links.reports.profitLossReport },
            { name: 'Certificate Report', href: links.reportLink + '/' + links.reports.certificateReport }
        ];

        this.actionLinks = [
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Appointment Card', href: links.appointmentCardLink + '/' + links.appointmentCard.List },
            { name: 'Submit Payment', href: links.submitPaymentLink + '/' + links.submitPayment.List },
            { name: 'My Yearly goal', href: links.myGoal }
        ];

        this.adminSettings = [
            { name: 'Item Management', href: '1' },
            { name: 'User Management', href: '2' },
            { name: 'Payments', href: '13' },
            { name: 'Survey Result', href: '3' },
            { name: 'Sales Management', href: '4' },
            { name: 'LGFG Certificates', href: '5' },
            { name: 'Our Goal', href: '6' },
            { name: 'Exchange Rate to CAD', href: '9' },
            { name: 'Provinces and Cities', href: '10' },
            { name: 'Office management', href: '11' },
            { name: 'EA/Clothier Deposit Email Notifications', href: '7' },
            { name: 'Alteration Category Management', href: '12' },
            { name: 'Reassign Report', href: links.reportLink + '/' + links.reports.reassignReport },
            { name: 'Package Discount Management', href: '17' }
        ];

        if (this.context.isTechSupport()) {
            this.adminSettings.push({ name: 'Reassign Rule Management', href: '14' });
            this.adminSettings.push({ name: 'Logs', href: '8' });
            this.adminSettings.push({ name: 'Emails', href: '15' });
            this.adminSettings.push({ name: 'Remove Discount', href: '16' });
            this.reportLinks.push({ name: 'QB Report', href: links.reportLink + '/' + links.reports.clothierQBReport });
            this.reportLinks.push({ name: 'New Clients Report', href: links.reportLink + '/' + links.reports.newClientReport });
        }
    }

    private setAccountingLinks(): void {
        this.reportLinks = [
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
            { name: 'My Items Report', href: links.reportLink + '/' + links.reports.itemsReport },
            { name: 'My Cashflow Report', href: links.reportLink + '/' + links.reports.cashflowReport },
            { name: 'TotalNCPBank Report', href: links.reportLink + '/' + links.reports.totalNCPBankReport },
            { name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport },
            { name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport },
            { name: 'My Payments Report', href: links.reportLink + '/' + links.reports.paymentReport },
            { name: 'Rep Overdue Report', href: links.reportLink + '/' + links.reports.overdueReport },
            { name: 'Management Report', href: links.reportLink + '/' + links.reports.managementReport },
            { name: 'Rep Overview Report', href: links.reportLink + '/' + links.reports.overviewReport },
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport },
            { name: 'Remake Item Report', href: links.reportLink + '/' + links.reports.remakeItemReport },
            { name: 'Profit Loss Report', href: links.reportLink + '/' + links.reports.profitLossReport },
            { name: 'Certificate Report', href: links.reportLink + '/' + links.reports.certificateReport },
        ];

        this.actionLinks = null;

        this.adminSettings = [
            { name: 'Item Management', href: '1' },
            { name: 'User Management', href: '2' },
            { name: 'Payments', href: '13' },
            { name: 'Sales Management', href: '4' },
            { name: 'LGFG Certificates', href: '5' },
            { name: 'Exchange Rate to CAD', href: '9' },
            { name: 'Provinces and Cities', href: '10' },
            { name: 'Office management', href: '11' },
            { name: 'Alteration Category Management', href: '12' },
            { name: 'Package Discount Management', href: '17' }
        ];
    }

    private setEaLinks(): void {
        this.reportLinks = this.reportLinks.concat([
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
        ]);

        this.actionLinks = this.actionLinks.concat([
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Appointment Card', href: links.appointmentCardLink + '/' + links.appointmentCard.List },
            { name: 'Submit Payment', href: links.submitPaymentLink + '/' + links.submitPayment.List },
        ]);
    }

    private setSmLinks(): void {
        this.reportLinks = this.reportLinks.concat([
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport }
        ]);

        this.actionLinks = this.actionLinks.concat([
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Appointment Card', href: links.appointmentCardLink + '/' + links.appointmentCard.List },
        ]);
    }

    private setRepLinks(): void {
        this.reportLinks = this.reportLinks.concat([
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
            { name: 'My Items Report', href: links.reportLink + '/' + links.reports.itemsReport },
            { name: 'My Cashflow Report', href: links.reportLink + '/' + links.reports.cashflowReport },
            { name: 'TotalNCPBank Report', href: links.reportLink + '/' + links.reports.totalNCPBankReport },
            { name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport },
            { name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport },
            { name: 'My Payments Report', href: links.reportLink + '/' + links.reports.paymentReport },
            { name: 'Rep Overdue Report', href: links.reportLink + '/' + links.reports.overdueReport },
            { name: 'Rep Overview Report', href: links.reportLink + '/' + links.reports.overviewReport },
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Top Clients Report', href: links.reportLink + '/' + links.reports.topClientReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport },
        ]);

        this.actionLinks = this.actionLinks.concat([
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Submit Payment', href: links.submitPaymentLink + '/' + links.submitPayment.List },
            { name: 'My Yearly goal', href: links.myGoal }
        ]);
    }
}
