export const buildingClientBaseQuestions = [
  {
    question: `In an optimally “mature” business, what % of your sales come from repeat business?`,
    answers: [
      { number: "A", isCorrect: false, text: `100%` },
      { number: "B", isCorrect: true, text: `80%` },
      { number: "C", isCorrect: false, text: `60%` },
      { number: "D", isCorrect: false, text: `40%` }
    ],
    explanation: `80%. The majority of your business should come from repeat clients but with room to replenish clients who will inevitably fall of as life happens.`
  },
  {
    question: `Which is optimal amongst the options below for someone who is a top producer:`,
    answers: [
      { number: "A", isCorrect: false, text: `Nearly all monthly business comes from repeats booked for new ideas` },
      { number: "B", isCorrect: true, text: `Nearly all monthly business comes from upsells on delviery` },
      { number: "C", isCorrect: false, text: `Nearly all monthly business comes from new referral meetings` },
      { number: "D", isCorrect: false, text: `Nearly all monthly business comes from floats` }
    ],
    explanation: `Most of your business as a top producer will come from repeats, and ideally that’ll happen on delviery so that you’re maximising your time and minimising your client bleed by keeping your clients constantly with an open book.`
  },
  {
    question: `Which is the highest predictor for a buyer to become a client?`,
    answers: [
      { number: "A", isCorrect: false, text: `Size of first order is above $3000` },
      { number: "B", isCorrect: false, text: `First order consists of more than 3 items` },
      { number: "C", isCorrect: true, text: `Client makes second purchase within 6 months of first purchase` },
      { number: "D", isCorrect: false, text: `Client's immediate need to update their wardrobe` }
    ],
    explanation: `The highest predictor for "client stickiness" ie that a client stays and keeps buying from a company is that they make a second purchase within 6 months of their first purchase.`
  },
  {
    question: `Which is a predictor that a new client won't buy again?`,
    answers: [
      { number: "A", isCorrect: false, text: `First delivery is a remake` },
      { number: "B", isCorrect: false, text: `First order consists of 1 shirt` },
      { number: "C", isCorrect: false, text: `Client tells you up front they're difficult to work with` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `While nothing works 99% of the time in sales, and everything will work 1% of the time, general experience shows that if you flub a first delivery, sell a measly shirt or the client is self-aware that they're hard to deal with, then your chances of not wanting to deal with this client again, or them not wanting to deal with you again, are high.`
  }
];
