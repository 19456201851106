export const leadershipQuestions = [
  {
    question: `You try to teach your rookie and they use the excuse, "but I saw another leader do it DIFFERENTLY!" What's the best response?`,
    answers: [
      { number: "A", isCorrect: false, text: `Explain to them that you teach one way and they may teach a different way, but you are their leader, not the other person` },
      { number: "B", isCorrect: true, text: `Privately call the other leader in question, explain the situation and connect them for a call with the rookie, so that the other leader can explain that they do follow the company training and that they're not comfortable with rookie using them (the other leader) as a way to break protocol and challenge their leader's competence and avoid accountability in their training`, },
      { number: "C", isCorrect: false, text: `Give an ultimatum that they should do it your way, or leave` },
      { number: "D", isCorrect: false, text: `Agree to try it the rookie's way` }
    ],
    explanation: `Undermining the company's methods or giving in to the rookie's (untrained and unproven) methods won't lead to positive outcomes. It means that the rookie either saw something in a different context and is applying it incorrectly because they don't have the situational awareness of what's happening yet, or the rookie is challenging your own competence. It's good to clear this up and have the other leader take responsibility and promote the company's methods, to flush out a rookie who argues about how to do it. This is a common situation you should know how to handle.`
  },
  {
    question: `A rookie quits, but writes you a great review on Linkedin for your leadership skills. He tells you in private that he likes you, but doesn't see a future at the company, for whatever reason. What does this mean?`,
    answers: [
      { number: "A", isCorrect: false, text: `You got a good person who simply doesn't fit into the product we're selling` },
      { number: "B", isCorrect: true, text: `The rookie secretly thinks you're a clown` },
      { number: "C", isCorrect: false, text: `The rookie might come back in the future` },
      { number: "D", isCorrect: false, text: `The rookie shouldn't be in sales` }
    ],
    explanation: `Just like when a prospect says "you're a really good salesperson!" but doesn't buy from you... they don't trust you and they're placating you with a little "pat on the back," to tell you that you're a nice little boy or girl. It's patronising. When a rookie tells you that you're great, but they quit, they're simply telling you that you-as their leader- caused them to not trust the company somehow. They may not even be aware of how patronising the message is. You may have undermined your leader, undermined the company's teaching methodologies and conflated them with your own conflicting methodologies... a number of reasons. You're being perceived as a clown, by a clown because you're either seen as (in their opinion) too incompetent to lead them, or as (in their opinion) gullible for following a stupid system or (in their opinion) working for stupid people. Take the rejection and learn from it.`
  },
  {
    question: `You're currently in a quarrel with another leader in the company. Your rookie asks you about it. What should you tell them?`,
    answers: [
      { number: "A", isCorrect: false, text: `Tell your side of the story. It's the truth. You should always tell exactly what's happening because you believe in your value system and when you're right, you're right, and there's nothing wrong with that.` },
      { number: "B", isCorrect: false, text: `Tell the rookie you don't want to talk about it because it's none of their business.` },
      { number: "C", isCorrect: true, text: `Tell the rookie that if there's any disagreement with another person, as can happen and should happen in a healthy relationship, it's fixed only by discussing it with the person directly and that you trust that the other person also has good interests in mind` },
      { number: "D", isCorrect: false, text: `Undercut the other leader in order to gain status points` }
    ],
    explanation: `Don't ever try to convince someone below you in the organisation about why another leader might be in the wrong. You're not just undermining the other leader- you are undermining leadership in the company as a whole and therefore undermining yourself. It will come back to haunt you. People talk and misinterpret and gossip spreads- that kills your team. Stay away from gossip- or creating gossip- at all costs.`
  },
  {
    question: `A rookie is in their 3rd month, not doing particularly well in terms of results yet, and they call you and ask you if they can work from home because it's easier than commuting to the office. What's happening?`,
    answers: [
      { number: "A", isCorrect: true, text: `They are considering quitting and will be using the time to look for a new job` },
      { number: "B", isCorrect: false, text: `They simply want to do better, and not having to travel will give them more time to call` },
      { number: "C", isCorrect: false, text: `They just want to avoid working hard because the expectations are inconceivably high for them` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `They know they're not performing as well as you do. It's disrespectful to break protocol when you're new and when you're underperforming - they know that they're not entitled to such privileges, unless they're completely delusional. More likely, they've already quit but you won't find out for another few weeks, while they look for a job.`
  },
  {
    question: `You are putting in time as a leader for 11 months with a rookie, but your rookie isn't performing well. What's the most correct statement from the list below:`,
    answers: [
      { number: "A", isCorrect: false, text: `You shouldn't worry about a rookie's results in month 11 and it's all about the work ethic` },
      { number: "B", isCorrect: true, text: `You as a leader are going to have to face accountability that your rookie isn't performing, and that can be a sign of an issue of poor leadership` },
      { number: "C", isCorrect: false, text: `You aren't getting paid for leadership since they're not performing - you should ask for a raise to make it worth your time` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `They are not performing in Month 11; by month 11, they should be able to sell 25K+ consistently - at least. You may have missed the earlier boat on training them in some aspects, or you're not recognising that they're just not going to grow and it's time to move them along.`
  },
  {
    question: `A rookie quits on their 3rd day at the company. What's the most correct statement from the list below:`,
    answers: [
      { number: "A", isCorrect: true, text: `You hired the wrong person for our company` },
      { number: "B", isCorrect: false, text: `You should have spent more time with them in the first 2 days` },
      { number: "C", isCorrect: false, text: `You should have a heart to heart to work out their issue and help them stay so that they can enjoy the benefits of an amazing career here` },
      { number: "D", isCorrect: false, text: `You probably did something wrong and you should ask them what you did wrong` }
    ],
    explanation: `It's their 3rd day. See ya later, alligator. No point in convincing them, or asking them - they don't know anything about the company other than our values by now- and our values clearly don't match. They were the wrong person for our company.`
  },
  {
    question: `What's the top predictor that a rookie will quit?`,
    answers: [
      { number: "A", isCorrect: true, text: `They have notes on their calendar to call back people who didn't book the first time` },
      { number: "B", isCorrect: false, text: `They are sometimes 5 minutes late for work` },
      { number: "C", isCorrect: false, text: `They have a hard time with the phone script` },
      { number: "D", isCorrect: false, text: `All of the above` }
    ],
    explanation: `None of the above are "positive" signs, but the most surefire way to know someone will quit is if they don't trust you. The most obvious sign is that they break the first and most important rule we teach:  You don't call people back who didn't book. If they don't trust you already on the first and most basic thing, they will resent you when they face the consequences and extreme negative emotion of calling people back and having their dignity diminished for it.`
  },
  {
    question: `What is a sign that your rookie might be about to quit?`,
    answers: [
      { number: "A", isCorrect: false, text: `Rookie is taking vacation days in their first 6 months` },
      { number: "B", isCorrect: false, text: `Rookie has to take a few days of sick leave in their first 6 months` },
      { number: "C", isCorrect: false, text: `Rookie asks to extend their base` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `All of the above indicate a rookie may be about to quit. Vacation days in the first 6 months is a huge red flag.<br>Sick days - especially after you can see the rookie is emotionally struggling is a huge red flag. Asking to extend their base is likewise a huge red flag as it shows the rookie is betting against themselves or simply didn't come prepared with the realities of building a business.`
  },
  {
    question: `What is a sign of having a low leadership lid?`,
    answers: [
      { number: "A", isCorrect: false, text: `Trusting your rookie more than trusting your leaders` },
      { number: "B", isCorrect: false, text: `Having a poor leadership record and nonetheless trusting your leadership instincts over trusting your leaders` },
      { number: "C", isCorrect: false, text: `You can't sell and lead at the same time` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `All of the above indicate a low leadership lid.`
  },
  {
    question: `Which of the following will end a rookie's career?`,
    answers: [
      { number: "A", isCorrect: false, text: `You're a top producer but leave early on Tuesdays to pick your kids from school` },
      { number: "B", isCorrect: true, text: `After a company call about selling jeans, you point out that you personally don't sell Jeans because they don't come out right from the factory` },
      { number: "C", isCorrect: false, text: `Rookie finds out that you previously had a rookie who didn't make it` },
      { number: "D", isCorrect: false, text: `All of the above` }
    ],
    explanation: `Undermining trust in the company with the rookie on one thing will undermine their trust on all things. That ultimately undermines their career.`
  },
  {
    question: `What is a key leadership skill for effectively growing a direct sales team?`,
    answers: [
      { number: "A", isCorrect: false, text: `Only focusing on selling, not on team development` },
      { number: "B", isCorrect: false, text: `Recruiting new team members without offering training` },
      { number: "C", isCorrect: true, text: `Coaching team members regularly and leading by example` },
      { number: "D", isCorrect: false, text: `Ensuring no one on the team is competing for recognition` }
    ],
    explanation: `It's not rocket science; do a good job at the thing you're asking your team to do, and give consistent coaching to your team, and things usually work out just fine!`
  },
  {
    question: `Which of the following is a primary role of a direct sales leader?`,
    answers: [
      { number: "A", isCorrect: false, text: `Closing all deals personally` },
      { number: "B", isCorrect: true, text: `Following a laid-out plan and inspecting work to provide timely coaching` },
      { number: "C", isCorrect: false, text: `Focusing on individual success over team growth- the rookie will follow whatever you do, perfectly!` },
      { number: "D", isCorrect: false, text: `Handling all customer complaints themselves to keep the rookie away from trouble` }
    ],
    explanation: `We have a training plan- it works! Inspect your rookie's work just like the plan says - it works!`
  },
  {
    question: `Sales leaders who perform have the following trait:`,
    answers: [
      { number: "A", isCorrect: false, text: `Publicly holding the team responsible for their failures` },
      { number: "B", isCorrect: true, text: `Taking full responsibility for the team’s failures` },
      { number: "C", isCorrect: false, text: `Allowing underperformance to continue unchecked` },
      { number: "D", isCorrect: false, text: `Avoiding direct feedback to keep morale high` }
    ],
    explanation: `The credit is theirs when they succeed, but the blame is yours when they fail.`
  },
  {
    question: `How can a direct sales leader best support new recruits in their early days?`,
    answers: [
      { number: "A", isCorrect: false, text: `Assigning them clients immediately` },
      { number: "B", isCorrect: false, text: `Leaving them to figure things out on their own` },
      { number: "C", isCorrect: true, text: `Offering hands-on training and a structured onboarding process` },
      { number: "D", isCorrect: false, text: `Pushing them to meet high targets right away` }
    ],
    explanation: `Onboarding and training is what new rookies need.`
  },
  {
    question: `A direct sales leader can increase sales productivity by:`,
    answers: [
      { number: "A", isCorrect: false, text: `Offering a one-size-fits-all approach to selling` },
      { number: "B", isCorrect: true, text: `Training team members to use effective time management and sales techniques` },
      { number: "C", isCorrect: false, text: `Focusing only on closing deals themselves` },
      { number: "D", isCorrect: false, text: `Delegating important tasks with minimal instruction` }
    ],
    explanation: `Rookies need to understand the reason why they're doing things; why is our script the way it is, for example?<br>If they understand the principle of what they're trying to accomplish, they will "own it" and grow. This requires training not only on sales techniques, but also on time-management; hence, watch them work and give them feedback directly.`
  },
  {
    question: `Which is a key benefit of coaching in direct sales leadership?`,
    answers: [
      { number: "A", isCorrect: true, text: `It improves both individual and team performance over time` },
      { number: "B", isCorrect: false, text: `It helps leaders focus only on high performers` },
      { number: "C", isCorrect: false, text: `It replaces formal training programs` },
      { number: "D", isCorrect: false, text: `It guarantees immediate sales success` }
    ],
    explanation: `There's no guarantees in sales and there's no immediate success in almost anything, but coaching, over time, with consistency, will improve team and individual performance.`
  },
  {
    question: `You have a new process introduced by the company, and it doesn't totally make sense to you, and your rookie approaches you, pointing out the new process doesn't make sense. What do you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Since you don't agree with the new process yourself, you tell the rookie that you emphasize with them and you don't like the change` },
      { number: "B", isCorrect: false, text: `You ignore the new process because there's no reason to change what's been working for you` },
      { number: "C", isCorrect: false, text: `You rally others to your side and approach management together - it's better to have everyone's support in order to democratically get the result you're seeking` },
      { number: "D", isCorrect: true, text: `You don't validate the rookie's concern about leadership's decision to them, even despite the fact that you also have the concern, and you have a call with leadership to go through the process and understand it better in order to see the vision behind it` }
    ],
    explanation: `Undermining leadership (even when you don't outright agree) will lead the rookie to eventually undermine you. It's a direct cause and effect. Best way forward is to try to understand the situation from higher vantage point, by going through with your leadership in order to see the long-term picture with information that you perhaps didn't have before.`
  },
  {
    question: `How can a leader in direct sales improve team retention?`,
    answers: [
      { number: "A", isCorrect: false, text: `By focusing solely on short-term sales targets` },
      { number: "B", isCorrect: true, text: `Offering professional development opportunities and career growth paths` },
      { number: "C", isCorrect: false, text: `By keeping a high turnover rate to get fresh talent` },
      { number: "D", isCorrect: false, text: `Offering little support and focusing on immediate results` }
    ],
    explanation: `Coaching, development and career growth is what keeps great people around. If you can't offer the leadership for them to stay, someone else will.`
  },
  {
    question: `You have a policy whereby if a rookie misses 5 days of work in a month, they are still entitled to their full commission but they don't get special bonuses. This policy is open and transparent, and everyone, including the rookies, know it and have been taught it. Your rookie missed 7 days last month but nonetheless was the top rookie in sales for the month in the company. She approaches you to ask for special bonuses that she would be entitled to if she didn't miss her work days, because she had good results for the month. What do you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `She performed well, so give her the bonuses` },
      { number: "B", isCorrect: false, text: `Give her 50% of the bonuses because it's a good balance` },
      { number: "C", isCorrect: true, text: `Go through the policy with her again and point out that it was communicated in advance, she knew what she was doing and it's not right to come to you after the fact, having known she was making the decision to take time off for herself` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `It's good for people to be recognised for their success! However, if people don't own their decisions, then they'll constantly negotiate terms with you until you're tired and worn out. As long as rules are clearly communicated and there are no surprises, and everyone agreed on the rules in advance, then you should honour the agreements; it makes everyone's life easier in the long run, and treats everyone fairly.`
  },
  {
    question: `Your rookie is working hard but isn't making money in month 9 and he is asking you for a base salary so he can make it through the year. What do you do?`,
    answers: [
      { number: "A", isCorrect: true, text: `Don't sustain that which cannot sustain itself` },
      { number: "B", isCorrect: false, text: `If they're a hard worker, pay them so that they can make it` },
      { number: "C", isCorrect: false, text: `Offer them 50% base so that they can survive` },
      { number: "D", isCorrect: false, text: `Increase their commission so they have some personal responsibility to succeed, but given their low results, they'll need the boost to make it through the hard first year` }
    ],
    explanation: `We do not sustain that which cannot sustain itself. Lots of rookies will make it. Some won't. By taking the pay away from the market and onto yourself, you remove performance-based thinking, and you take away the rookie's accountability - this will poison them for the future.`
  },
  {
    question: `You have a guy in the office who is in his 2nd month. You overhear him talking to another rookie, and he's dumping on the company about how the training isn't great and the product is suspect. What do you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Pull the guy aside and go through concerns with them` },
      { number: "B", isCorrect: false, text: `Take both rookies aside and go through all the concerns with them together` },
      { number: "C", isCorrect: false, text: `Pull out the other rookie (not the complaining guy) and tell them not to listen to mr.complaino` },
      { number: "D", isCorrect: true, text: `Fire the complainer` }
    ],
    explanation: `If they have the arrogance to neg on the company and contribute to toxic culture in their second month, you should have the gumption to replace them immediately with a less toxic person.`
  },
  {
    question: `You have a guy in the office who is in his 6th month. He trusts you as their leader tremendously. However, they don't trust the company. What does this mean?`,
    answers: [
      { number: "A", isCorrect: true, text: `It's a clear sign that you have a huge ego that's preventing you from becoming a successful leader` },
      { number: "B", isCorrect: false, text: `You have a lot of impact with them so you can influence them to stay and do well. As long as you maintain a good relationship with this person, you can be a good leader to them` },
      { number: "C", isCorrect: false, text: `The rookie will probably stay, but their performance won't be very good` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `This is a sign that the leader has consistently taken actions that make themselves look good but have undermined the company. This leader has a huge ego and it will create a flow of people who quit on them but remain their "friend" which creates an ego-feedback loop that the leader is doing everything right and the company is wrong. Sadly, the leader will simply not grow a team and ultimately keeps shooting themselves in the foot by recruiting friends who smile to their face, but think they're a clown behind their back.`
  },
  {
    question: `What most clearly demonstrates your leadership lid?`,
    answers: [
      { number: "A", isCorrect: false, text: `Your sales figures` },
      { number: "B", isCorrect: false, text: `How much your team likes you (how highly they speak of you)` },
      { number: "C", isCorrect: true, text: `Your team's sales figures and retention` },
      { number: "D", isCorrect: false, text: `All of the above` }
    ],
    explanation: `You can have people speak highly of you and quit or not sell, and you can sell a lot but not be able to lead. Only your team's sales results and retention speak to your leadership lid.`
  },
  {
    question: `You have a new rookie on your team. However, you also have a 3rd year salesperson in the office who shows up when he feels like it and doesn't have a great work ethic when he's there. The 3rd year salesperson sells "just fine" but is not a good example for the rookie. What should you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Fire the 3rd year salesperson` },
      { number: "B", isCorrect: false, text: `Don't need to do anything- continue as normal` },
      { number: "C", isCorrect: false, text: `Force the 3rd year salesperson to come to the office and work a "normal" schedule and be there working hard every day, or they'll have to be removed from the company` },
      { number: "D", isCorrect: true, text: `Have a private talk with the 3rd year salesperson and explain the situation and ask them to either do what "C" says or work remote 100% of the time, because he's setting a bad example but he can continue to sell without interfering with the leadership objective` }
    ],
    explanation: `When you have someone who sells but is harming the leadership objective, they should either buy-in, or be isolated.`
  },
  {
    question: `You have a rep on your team who is very good at selling and consistently finishes as a top rep. However, you catch him "gaming the company" in a way that he is able to transfer some of what the client paid for their products to their NCP, thus delivering a lower line to the client than what the client paid for, and pocketing the excess to themselves. What do you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Confront the rep one on one in private and ask them to stop that behaviour` },
      { number: "B", isCorrect: true, text: `Fire the rep` },
      { number: "C", isCorrect: false, text: `Don't do anything because "if it works, it works"` },
      { number: "D", isCorrect: false, text: `Introduce a new policy outlining a rule that captures that behaviour and prevents it in the future` }
    ],
    explanation: `You don't need to write rules for basic human values. Some things should just be expected to come standard as part of a person's moral compass. If you get a person who doesn't operate on basic human principles, like "don't steal" then it doesn't matter how much they sell- they will absolutely destroy your business, because of their values. Fire immediately.`
  },
  {
    question: `A rep on your team asks if they can sell an unrelated product to their clients, for example, some real estate deals. They already have a relationship with the client and the product doesn't compete with clothing. How do you proceed?`,
    answers: [
      { number: "A", isCorrect: false, text: `No issue here. Different product` },
      { number: "B", isCorrect: true, text: `Hard No` },
      { number: "C", isCorrect: false, text: `Check out the other offering for yourself to make sure it's legit, and if so, then no problem, but if it's 'scammy' then No` },
      { number: "D", isCorrect: false, text: `Run it up the chain to see if leadership might have all reps in the company sell it` }
    ],
    explanation: `Hard No. Clients will feel ambushed and the foundational trust upon which the relationship was built is being undermined, while other reps who discover this behaviour will feel undermined as well - as it undermines the core objective and vision of the business. This will hurt the clients long-term and the other reps in the company long-term. Hard No.`
  },
  {
    question: `An experienced rep on your team took excess vacation time over the course of 2 months, and as a result, didn't produce their 3 months required average to retain their vacation pay (i.e. got reclassified as a part-time rep). By policy, this means the rep has lost their vacation pay privileges for the next 12 months. The following month, that is on the month immediately following the last day of the quarter where they ended up losing their vacation pay privileges for 12 months, the rep sells $250,000, and asks to reinstate their vacation pay for the next 12 months. What is the principally correct thing to do?`,
    answers: [
      { number: "A", isCorrect: false, text: `$250,000 is $250,000. Considering the rep made up for lost time with sales, it makes sense to not follow the policy so tightly and to allow that rep to recover their vacation pay for the 12 months. Ultimately, the final results are what matter.` },
      { number: "B", isCorrect: true, text: `The rep knowingly didn't work over the course of 2 months and knew what the policy was and nonetheless chose to ignore it. Reinstating their vacation after a good month that followed would incentivise poor organisational behaviour being rewarded by bending the rules for short term outcomes. While it's good that the rep sold a lot the month following, they should have respected the policy set up exactly to incentivise consistency in order to build trust within the organisation.` },
      { number: "C", isCorrect: false, text: `A new target should be established in order to help the rep recover their vacation pay.` },
      { number: "D", isCorrect: false, text: `It doesn't sound like a great policy and perhaps it should permanently be changed` }
    ],
    explanation: `Vacation pay is for full-time employees and for those who hit full-time quotas. When they are not hit, the rep is effectively a part-time rep. A part-time rep can still make a great income and great bonuses! However, undermining organisational goals (and important cash flow projections needed to run the company, one of the bases on which this policy was established) cannot be incentivised as it will disorient the principles required to grow a successful business. If people choose to be part-time, that can be arranged for sure, but part-time people objectively should not get the same privileges as those who work full-time. This is a privilege the rep lost due to their own actions and choices, and they'll have to re-earn their status just like everyone else who has been earning it.`
  },
  {
    question: `What is the primary and most important item on this list to teach to new salespeople:`,
    answers: [
      { number: "A", isCorrect: false, text: `Teaching salespeople to close every deal` },
      { number: "B", isCorrect: true, text: `Ensuring all team members understand the company’s goals and values` },
      { number: "C", isCorrect: false, text: `Providing discounts and promotions to customers` },
      { number: "D", isCorrect: false, text: `Minimizing the training period to get salespeople into the field quickly` }
    ],
    explanation: `The most important and first-taught thing needs to be the goals and values of the company. That's why we have an onboarding system. Before we teach them, we need them to know who we are, so that they can quickly decide if they're in or out.`
  },
  {
    question: `Which of the following is the most essential when training sales leaders?`,
    answers: [
      { number: "A", isCorrect: false, text: `Learning how to manage each team member's calendar and balance it with your own` },
      { number: "B", isCorrect: false, text: `Learning how to teach phone-calling` },
      { number: "C", isCorrect: true, text: `Developing leadership skills` },
      { number: "D", isCorrect: false, text: `Using Drivepipe and other tools effectively` }
    ],
    explanation: `Without developing leadership, nothing else works - great tools are useless with poor leadership.`
  },
  {
    question: `Which strategy is most effective for aligning a direct sales team’s performance with the company’s long-term objectives?`,
    answers: [
      { number: "A", isCorrect: false, text: `Implementing daily sales targets` },
      { number: "B", isCorrect: false, text: `Offering bonuses for closing large deals` },
      { number: "C", isCorrect: false, text: `Allowing salespeople to choose their own sales process` },
      { number: "D", isCorrect: true, text: `Tracking and upholding clear activity indicators and measurable goals` }
    ],
    explanation: `Big deals don't come without big habits.`
  },
  {
    question: `A rookie in their 3rd month has just sold more than you for the month. What does likely it mean?`,
    answers: [
      { number: "A", isCorrect: false, text: `Great news: you have a star on your team!` },
      { number: "B", isCorrect: true, text: `Rookie will likely quit soon` },
      { number: "C", isCorrect: false, text: `Dumb luck. Meaningless` },
      { number: "D", isCorrect: false, text: `It’s never happened so we don’t know` }
    ],
    explanation: `You’re promising a rookie an amazing future. If they’re already better than you from the start, it means they can’t trust your promises because they will interpret their result as already objectively being better than you.<br>We’ve seen this time and time again: if a rookie outsells you that early, they’ll quit because they lose respect for you. Of course this doesn’t mean you should hold your rookie back: it just means you got someone on your team who is operating at a higher level than you, and you’ll need to be better yourself in order to keep better people.`
  },
  {
    question: `A rookie in their 10th month has just sold more than you for the month. What does it likely mean?`,
    answers: [
      { number: "A", isCorrect: true, text: `Great news: you have a star on your team!` },
      { number: "B", isCorrect: false, text: `Rookie will likely quit soon` },
      { number: "C", isCorrect: false, text: `Dumb luck. Meaningless` },
      { number: "D", isCorrect: false, text: `It’s never happened so we don’t know` }
    ],
    explanation: `You want your rookie to be a better salesperson than you! A sign of a leader is someone who builds someone to be better than themselves. Of course if it happened right away, that would just show your own lid being too low and undermine trust that you can take the rookie where you promised to take them. But once they're going and are fully able to start to pursue their potential, it's great that they grow and if they start taking off then what a great thing it is!`
  },
  {
    question: `Which of the following is a mistake that will undermine your rookie’s success?`,
    answers: [
      { number: "A", isCorrect: false, text: `Wearing a different brand for a product we sell because you prefer it over LGFG` },
      { number: "B", isCorrect: false, text: `Telling a rookie that nobody really buys ties anymore so there’s no point in lugging them around` },
      { number: "C", isCorrect: false, text: `Telling your rookie about how you’re not getting along with another leader in the company` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `All of the above will severely undermine the chances for a rookie to succeed. Much like a mistake in a sales presentation will lead the client not to buy, these are all critical mistakes that will lead to a rookie not staying in the company.`
  },
  {
    question: `A rookie in month 6 has just finished #1 in sales not just for a rookie, but in the whole company! What does it mean?`,
    answers: [
      { number: "A", isCorrect: false, text: `Great news: you have a star on your team!` },
      { number: "B", isCorrect: true, text: `Rookie will likely quit soon` },
      { number: "C", isCorrect: false, text: `Dumb luck. Meaningless` },
      { number: "D", isCorrect: false, text: `It’s never happened so we don’t know` }
    ],
    explanation: `The rookie has demonstrated very early on - before they can master the new sales process as we teach it - that they’re already the best amongst their peers. This is indicative that you’re dealing with a “A” player and their lid is higher than the organisation, and “A” players want to be around those who will help them grow- they’ve just proven this company isn’t it. They’ll leave soon.`
  },
  {
    question: `Your rookie simply refuses to follow the system- their contacts aren’t great, their sales talk is weak, but they just sold 40k in month 2. What does it mean?`,
    answers: [
      { number: "A", isCorrect: false, text: `Great news: you have a star on your team!` },
      { number: "B", isCorrect: true, text: `Rookie will likely quit soon` },
      { number: "C", isCorrect: false, text: `Dumb luck. Meaningless` },
      { number: "D", isCorrect: false, text: `It’s never happened so we don’t know` }
    ],
    explanation: `The rookie ran into some luck for sure, but there’s a worse element there: they now think they know better than you and the company and their technical game will just stay weak forever. They might now actually quit right away, but their lack of technical skill and emotional buy in will now cause them to have a lower lid and inconsistent results. They’ll hit a wall and won’t know how to break through because they don’t have the foundation, and you’ll slowly watch them fall apart. This a story in sales as old as time.`
  },
  {
    question: `You’re a really good salesperson but everyone you hire quits fast or sticks around and doesn’t produce. Other leaders in the company can consistently deliver rookies that stay and perform. What’s the issue?`,
    answers: [
      { number: "A", isCorrect: false, text: `You are smarter than everyone else and those who are getting objectively better results than you are idiots` },
      { number: "B", isCorrect: false, text: `Your turf is special that’s why only you can succeed but nobody else can` },
      { number: "C", isCorrect: true, text: `Your leadership instincts are poor and you are therefore making consistently poor leadership decisions that are undermining your own results. You should probably be open to some mentoring and changing your actions until you stop doing the wrong things on account of your instincts will improve with good mentorship and development` },
      { number: "D", isCorrect: false, text: `No need to change anything. Every single rookie you got was a bad apple and eventually you’ll get the good that will stay and produce despite the fact that you’ll continue to rely on the same instincts which have previously consistently shown poor outcomes` }
    ],
    explanation: ``
  },
  {
    question: `Your 2nd year salesperson is talented but has been somewhat disconnected from their work, showing poor habits and poor buy-in. They complain about the methods the company is using, and suggest we need to update the colours of our website, along with updating DO so that we have a more “intuitive interface”. Furthermore, she gets into other people (like their EA) because they don’t like the job they’re doing. What’s the way forward?`,
    answers: [
      { number: "A", isCorrect: false, text: `Your rookie is smarter than everyone else. You should probably update the colors on the website.` },
      { number: "B", isCorrect: true, text: `Clown alarm. You have a toxic person who should be talked to about being promoted to a new company, far away from this one.` },
      { number: "C", isCorrect: false, text: `Everyone’s input is important and we should know everyone’s feedback on improving our systems. This is the foundation of everyone having an equal voice.` },
      { number: "D", isCorrect: false, text: `No need to change anything. People go through phases and this will all resolve itself.` }
    ],
    explanation: `A toxic person’s opinions can go terrorise someone else far, far away. The idea of equality of opinion is nonsensical in a meritocracy. That’s why you don’t take sales advice from your DQ’s. We don’t promote clown to clown communication.`
  },
  {
    question: `Your rookie has a tendency to sell combinations of clothing, example: vest+sweater, shoes+shirt+cufflinks, or pants+shorts: what does this mean?`,
    answers: [
      { number: "A", isCorrect: false, text: `Rookie is good at showing lots of ideas` },
      { number: "B", isCorrect: true, text: `Rookie has low sales skill` },
      { number: "C", isCorrect: false, text: `Rookie is building trust with first order` },
      { number: "D", isCorrect: false, text: `Rookie knows the product line very well` }
    ],
    explanation: `Low sales skill.<br>When a guy buys a shirt, or like 1 pant and 1 shirt, he’s probably spending more than he’s generally comfortable in the situation and he’s showing low trust (or it’s not necessarily an affording it thing but more his relationship with money thing) , hence the low package size and he expects it to be the most amazing magical thing ever. It’s hard to live up to that. Get ready for lots of alterations, followed by refunds.`
  },
  {
    question: `It's month 4 and your rookie just sold 40K. What should you NOT tell them:`,
    answers: [
      { number: "A", isCorrect: false, text: `40K is really amazing, good job!` },
      { number: "B", isCorrect: false, text: `You're way ahead of everyone at this time, you'll be a star` },
      { number: "C", isCorrect: false, text: `I thought you'd sell 50` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `You shouldn't say "A" because it sets a ceiling on their performance and you should be setting a floor.<br>You shouldn't say "B" because it sets a rookie up for emotional devastation (and loss of trust in you) if they underperform later.<br>You shouldn't say "C" because it undermines their achievement.<br>A better way to phrase it is "I'm really happy for your month and I know you're capable of even more" - that shows appreciation while not setting a ceiling.`
  },
  {
    question: `Your rookie is struggling in sales. Where's the first place you should look with them?`,
    answers: [
      { number: "A", isCorrect: true, text: `Inspect their activity` },
      { number: "B", isCorrect: false, text: `Inspect their script execution` },
      { number: "C", isCorrect: false, text: `Inspect their goals` },
      { number: "D", isCorrect: false, text: `All of the above` }
    ],
    explanation: `Everything comes secondary to activity. It doesn't mean that everyone who has the activity will make it, but certainly nobody without the activity will.`
  },
  {
    question: `Without continuous in-person inspection and repeated, in-person training, which of the following will rookies do wrong?`,
    answers: [
      { number: "A", isCorrect: false, text: `How they leave voicemails` },
      { number: "B", isCorrect: false, text: `The efficiency at which they work: "Income producing activities only during business hours"` },
      { number: "C", isCorrect: false, text: `How they text` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `Rookies will do the simplest things wrong, to the point where you can't even believe they could do it wrong. It's your job as a leader to inspect their work and watch how the execute, in order to repeatedly correct them.`
  },
  {
    question: `What's the best way to check if a rookie is following their sales training?`,
    answers: [
      { number: "A", isCorrect: false, text: `They tell you that they are and you should believe them` },
      { number: "B", isCorrect: false, text: `Inspect their Drivepipe, Calendar and phone recordings according to the leadership manual` },
      { number: "C", isCorrect: false, text: `Watch them work` },
      { number: "D", isCorrect: true, text: `B+C` }
    ],
    explanation: `You can't trust what a rookie tells you because they may believe they're following training when they're not.`
  },
  {
    question: `Which of the following conditions will cause a rookie to quit?`,
    answers: [
      { number: "A", isCorrect: false, text: `Rookie trusts the company, but not their leader` },
      { number: "B", isCorrect: false, text: `Rookie trusts their leader but not the company` },
      { number: "C", isCorrect: false, text: `Rookie trusts neither their leader not the company` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `Any broken link on the leadership chain causes the chain to break.`
  },
  {
    question: `A young rep, in her 8th month, goes to 20 meetings she booked for that month. She takes a cab to each meeting, however none of the 20 meetings buy; in fact, 12 of the meetings don't show up and the other 8 are "info sessions."At the end of the month, the rep has $1000 in Taxi fares that she has paid, but due to no sales, she can't cover the expense from her ENCP. What's the correct action for her leader?`,
    answers: [
      { number: "A", isCorrect: false, text: `The leader should appeal to the company to cover the taxi fares` },
      { number: "B", isCorrect: false, text: `The leader should pay the taxi fares for the rookie` },
      { number: "C", isCorrect: false, text: `The rookie should cover the taxi fares herself; if the rookie instead quits, the company should be responsible for covering the rookie's alteration expenses she owes to tailor` },
      { number: "D", isCorrect: true, text: `The rookie should cover the taxi fares herself; if the rookie instead quits, the leader should be responsible for covering rookie's alteration expenses she owes to tailor` }
    ],
    explanation: `Nobody but the rookie should be responsible, because according to the principle from "10 Pillars of Leadership," people need to experience the consequences of their own failure, and if the company or the leader steps in to cover the rookie's own bad decisions, we will be violating a principle that will nonetheless lead the rookie to failure. If the rookie quits and doesn't pay the expense, the accountability goes up to the next level on the chain- her direct leadership. There is no way the rookie should have had a "0" month based on not being able to qualify a meeting, let alone be taking taxis to 20 consecutive meetings who don't show up and don't buy. Leader should have caught that much earlier and at the very least called to requalify the meetings.`
  },
  {
    question: `There's a Division President in the company who has developed a number of very good salespeople, but the leader is retiring this year. At the end of the year, when the leader retires, there's nobody to take the leader's place, and the company promotes a younger leader, one who has developed 1-2 good salespeople, but did not reach Division President level, into the Division President position in order to cover the void in the position. What is the likely outcome?`,
    answers: [
      { number: "A", isCorrect: true, text: `The team, with the newly promoted Division President will fall apart and trickle out of the company` },
      { number: "B", isCorrect: false, text: `The team, being independent now, won't really care and keep doing what they were doing` },
      { number: "C", isCorrect: false, text: `A younger leader who is more "in touch" with the team will increase performance` },
      { number: "D", isCorrect: false, text: `Depends on the leadership level of the incoming leader` }
    ],
    explanation: `We already know the leadership lid of the incoming leader- the market sets the lid- this person hasn't reached the leadership level required for division president. With a lower lid placed on the team, they will fall apart and trickle out.`
  },
  {
    question: `How could the company ensure the best transition for the team after the Division President Leaves?`,
    answers: [
      { number: "A", isCorrect: false, text: `There is no need to assign a leader to the team` },
      { number: "B", isCorrect: true, text: `The team should be moved to a person of equal or greater leadership lid as dictated by the market` },
      { number: "C", isCorrect: false, text: `Someone within the team should lead the team` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `The team will operate fine with a person leading them who is of equal or greater leadership lid as dictated by the market.`
  },
  {
    question: `How can we rank the leadership level of the  Division President who retired?`,
    answers: [
      { number: "A", isCorrect: false, text: `Poor leader` },
      { number: "B", isCorrect: true, text: `A good but not great leader` },
      { number: "C", isCorrect: false, text: `A great leader` },
      { number: "D", isCorrect: false, text: `Not enough Information` }
    ],
    explanation: `Leader built and sustained a Division President level team - they are a good leader. However, a great leader is able to replace themselves. That didn't come from inside the team and that's a sign.`
  },
  {
    question: `Your team consists of a single, second-year salesperson who is producing an average of 20K a month. Which level leader are you?`,
    answers: [
      { number: "A", isCorrect: false, text: `Level 1` },
      { number: "B", isCorrect: true, text: `Level 2` },
      { number: "C", isCorrect: false, text: `Level 3` },
      { number: "D", isCorrect: false, text: `Level 4` }
    ],
    explanation: `If you can have people stay, but not really produce, you're a level 2 leader.`
  },
  {
    question: `You can't keep a salesperson for a year because either they quit or get fired, however you're #1 in the company in sales, what level leader are you?`,
    answers: [
      { number: "A", isCorrect: true, text: `Level 1` },
      { number: "B", isCorrect: false, text: `Level 2` },
      { number: "C", isCorrect: false, text: `Level 3` },
      { number: "D", isCorrect: false, text: `Level 4 ` }
    ],
    explanation: `You're a good example with your work ethic and competency but you're not able to keep people around. You're a level 1 leader.`
  },
  {
    question: `You've built a great client base and you're a very good producer in terms of sales, but you're not a good example to others because you're able to get away with part-time work, given your previous success at building a great client base. What level leader are you?`,
    answers: [
      { number: "A", isCorrect: false, text: `Level 1` },
      { number: "B", isCorrect: false, text: `Level 2` },
      { number: "C", isCorrect: false, text: `Level 3` },
      { number: "D", isCorrect: true, text: `None of the above` }
    ],
    explanation: `None of the above. The minimum to be a level 1 leader is to lead by example. If you can't model good habits to people around you - something nearly all people will need to be successful, then you're not qualified to be in any form of leadership.`
  },
  {
    question: `Which of the following describes leadership:`,
    answers: [
      { number: "A", isCorrect: false, text: `An inspirational speech to your rookie about how great your life is thanks to LGFG` },
      { number: "B", isCorrect: false, text: `Buying your rookie lunches every day` },
      { number: "C", isCorrect: false, text: `Telling your rookie what they need to be doing in order to become successful and make it` },
      { number: "D", isCorrect: true, text: `Modeling the exact behaviour your rookie needs to have for them to make it` }
    ],
    explanation: `Doing the thing is what gets the thing done by you and by others- all the other options are superficial short-term energy boosts that will last at most for an hour (if you're lucky).`
  },
  {
    question: `Your rookie will copy your behaviour by doing _____ % of what you do right and _____% of what you do wrong.`,
    answers: [
      { number: "A", isCorrect: false, text: `100%, 100%` },
      { number: "B", isCorrect: true, text: `50%, 400%` },
      { number: "C", isCorrect: false, text: `0%, 100%` },
      { number: "D", isCorrect: false, text: `100%, 0%` }
    ],
    explanation: `Your rookie will do half of what you do right and do a lot more of what you do wrong.`
  },
  {
    question: `You have thoroughly explained to your rookie how to leave a VM, what to say etc. You've practiced with them as well. A reasonable expectation based on real life observation from training many rookies should lead you to expect the following:`,
    answers: [
      { number: "A", isCorrect: false, text: `Since you explained and practiced with them, they will perform it perfectly going forward every time` },
      { number: "B", isCorrect: false, text: `They will do it right as long as you're in the room but when you're not in the room they'll revert to their own script` },
      { number: "C", isCorrect: true, text: `You'll have to go over it 5-8 more times with them over the course of the next several weeks or months for them to do it right consistently` },
      { number: "D", isCorrect: false, text: `They will always revert back to their own, invented script, no matter how much you try to help them` }
    ],
    explanation: `It will take 5-8 iterations over the course of weeks or months for a rookie to consistently execute one process. Expecting anything else is delusional.`
  },
  {
    question: `Which of the following describes when you have the LOWEST trust from your team members (rookies)?`,
    answers: [
      { number: "A", isCorrect: true, text: `When they start and during most of their first year` },
      { number: "B", isCorrect: false, text: `If they outsell you in month 10` },
      { number: "C", isCorrect: false, text: `When they have a remake` },
      { number: "D", isCorrect: false, text: `Before they close their first sale` }
    ],
    explanation: `It takes about a year for people to examine and evaluate the character of their leaders - trust is lowest from the start- if you're not consistently executing from the start, you're setting yourself up for failure later- the earlier you breach a rookie's trust, the more impact it has.`
  }
];
