<div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    @for(toast of toasts; track identify($index, toast))
    {
    <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <div class="me-auto">
                <div class="success-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                        class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                </div>
                {{ toast.message }}
            </div>
            <button type="button" class="btn-close" aria-label="Close" (click)="removeToast(toast)"> <svg
                    xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                    <path
                        d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z' />
                </svg></button>
        </div>
    </div>
    }
</div>