import { UserContextService } from './../core/service/user-context.service';
import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { TableColumn } from 'src/app/shared/data-table/table-column';
import { ModelList } from "src/app/core/models/models-list";
import { BaseListComponent } from 'src/app/core/base-list.component';
import { TableAction } from 'src/app/shared/data-table/table-action';
import { GlobalConstants } from 'src/app/core/global-constants';
import { FilterBase } from 'src/app/core/models/filter-base.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CardComponent } from "../shared/cards/card.component";
import { DataTableComponent } from "../shared/data-table/data-table.component";
import { MyGoalModel } from "../core/models/my-goal.model";
import { GoalService } from "../core/service/goal.service";
import { MyGoalModalComponent } from "./my-goal-modal/my-goal-modal.component";
import { ModalService } from '../core/service/modal.service';

@Component({
    selector: 'my-goal-tab',
    templateUrl: './my-goal-page.component.html',
    standalone: true,
    imports: [CardComponent, DataTableComponent, MyGoalModalComponent]
})
export class MyGoalPageComponent extends BaseListComponent implements AfterViewInit {
    @ViewChild('modal', { static: true }) myGoalModal: MyGoalModalComponent;

    public data = new ModelList<MyGoalModel>();
    public columns: TableColumn<MyGoalModel>[];
    public actions: TableAction<MyGoalModel>[];
    public filter: FilterBase;

    constructor(
        private goalService: GoalService,
        spinner: NgxSpinnerService,
        private userContextService: UserContextService,
        private modalService: ModalService) {
        super(spinner);

        document.title = "My Goals";

        this.columns = this.getColumns();
        this.actions = this.getActions();
    }

    public async ngAfterViewInit(): Promise<void> {
        this.loadingIndicator = true;
        this.spinner.show();
        await this.loadData();
        this.loadingIndicator = false;
        this.spinner.hide();
    }

    protected async loadData(): Promise<void> {
        this.data = await this.goalService.getMyGoals();
        this.filtredData = this.data.items;
        this.setRecordsNumbers();
    }

    protected setRecordsNumbers() {
        const pageOffset = (this.currentPage - 1) * this.limit + 1;
        this.data.items.forEach((i, index) => i.rowNumber = pageOffset + index);
    }

    private getColumns(): TableColumn<MyGoalModel>[] {
        var columns = [
            { prop: 'rowNumber', name: '#', flexGrow: 60 },
            { prop: 'year', name: 'Year', flexGrow: 300 },
            { prop: 'goal', name: 'Goal', flexGrow: 300, type: 'money' },
            { prop: 'current', name: 'Current', flexGrow: 300, type: 'money' }
        ];
        if (this.userContextService.isAdmin()) {
            columns.push({ prop: 'userName', name: 'User Name', width: 35 } as any);
        }

        return columns as any;
    }

    private getActions(): TableAction<MyGoalModel>[] {
        return [
            {
                icon: GlobalConstants.icons.view,
                name: 'Edit',
                handler: (item: MyGoalModel) => {
                    this.myGoalModal.open(item);
                }
            },
            {
                icon: GlobalConstants.icons.delete,
                name: 'Delete',
                handler: async (item: MyGoalModel) => {
                    if (await this.modalService.confirmAction('Delete item', 'Are you sure you want to delete this item?')) {
                        await this.goalService.deleteMyGoal(item.id);
                        this.modalService.openInformationModal('Item has been deleted successfully.');
                        await this.loadData();
                    }
                }
            }
        ];
    }
}
