import { Component } from '@angular/core';

interface Toast {
    message: string;
}

@Component({
    selector: 'toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: true,
})
export class ToastComponent {
    toasts: Toast[] = [];

    showToast(message: string): void {
        const newToast: Toast = { message: message };
        this.toasts.push(newToast);

        setTimeout(() => {
            this.removeToast(newToast);
        }, 3000);
    }

    removeToast(toast: Toast): void {
        const index = this.toasts.indexOf(toast);
        if (index !== -1) {
            this.toasts.splice(index, 1);
        }
    }

    identify(index: number, item: Toast) {
        return index;
    }
}