export const reschedulesAndCancelsQuestions = [
  {
    question: `What's the most correct, immediate reaction when someone asks you to reschedule or cancel?`,
    answers: [
      { number: "A", isCorrect: false, text: `Ask them when they'd like to meet instead` },
      { number: "B", isCorrect: false, text: `Immediately suggest a new meeting time` },
      { number: "C", isCorrect: true, text: `Ask them if they want to reschedule or they just want to cancel` },
      { number: "D", isCorrect: false, text: `Listen to when they tell you to call them back, and then do that` }
    ],
    explanation: `When someone reneges on an agreement they've already made, it's wrong to validate them by serving them even harder and following their instructions - they can't be trust; it's also wrong to offer a new time to meet because it's not your responsibility to amend their broken commitment when they might just have a hard time saying 'no' and are trying to avoid disappointing you. The correct reaction here is to confront using the scripts and give them the "in or out" so that either they muster the confidence to tell the truth and walk away because they're not interested, or to double-down that they want to reschedule. If they confirm they want to reschedule then you can offer a time. Note they will still try to get you to call them later once you do that, and that's why we have a  script for that because a second confrontation is likely coming. Follow the script.`
  },
  {
    question: `You call a prospect because they didn't accept your calendar invite, when you bring that up, they say "I actually can't make it that day, can you call me in a week?"<br>What's the best response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Sure"` },
      { number: "B", isCorrect: false, text: `"Sure, what time next week should I call you?"` },
      { number: "C", isCorrect: false, text: `"Why don't you get out your calendar and we can rebook right now"` },
      { number: "D", isCorrect: true, text: `"Do you want to reschedule, or should we just cancel?"` }
    ],
    explanation: `The correct course of action when someone suggests a "reschedule" is always to ask if they want to reschedule or just cancel. This is called a "tie down" in sales, and it affirms that you have permission to close the prospect on a specific time. Although, it's likely that even after this step you will have one more step to clear because the "call me in a (time period)" is likely coming. Follow the scripts for this - they work!`
  },
  {
    question: `Imagine you've handled Question 2 correctly and the prospect has agreed to reschedule. You've suggested a new time, and the prospect now says "just call me next week and we'll reschedule!" What's the correct response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"You don't seem so confident that you want to reschedule, maybe it would make more sense to just cancel this?"` },
      { number: "B", isCorrect: true, text: `"(name), I really appreciate that but I hate chasing, and we both know it’s not going to be any more convenient..."` },
      { number: "C", isCorrect: false, text: `"Ok I understand that you're busy. Let's connect next week when it's more convenient for you and we can decide on a better time!"` },
      { number: "D", isCorrect: false, text: `"We both know you won't pick up my calls anymore, so why are you playing this game?"` }
    ],
    explanation: `This is the correct scripted confrontation. It's a good balance of stating that you understand the truth without being too needy while also maintaining status by inferring you're socially intelligent enough to know what's happening, without being overly aggressive-sounding.`
  },
  {
    question: `You get an email from a prospect that he can't make it to today's meeting. What's the best way to respond?`,
    answers: [
      { number: "A", isCorrect: false, text: `Email back immediately using scripts` },
      { number: "B", isCorrect: true, text: `Pick up the phone call them right away` },
      { number: "C", isCorrect: false, text: `Text them using scripts` },
      { number: "D", isCorrect: false, text: `Wait until you're back by their office, and call them the day before to schedule` }
    ],
    explanation: `You should try to get them on the phone right away, and then follow the phone script for reschedule. If they don't pick up, only then immediately text them using scripts.<br><br>Phone -> text -> email.`
  },
  {
    question: `Despite the fact that you've been following scripts, a prospect has now rescheduled on you 3 times, but he's the CEO of a major company and he earns $50,000,000 per year and says he buys suits for $25,000 per piece. What should you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Keep following up` },
      { number: "B", isCorrect: true, text: `Forget they ever existed` },
      { number: "C", isCorrect: false, text: `Confront them about it` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `You've confronted them enough by now. Take the "L" and find a prospect of higher character. Save your dignity. Yes, even the richest people can be the crappiest. You're not competing against their schedule at this point; you're competing against their values. You won't win.`
  },
  {
    question: `You've booked a prospect but a day before the meeting they write to you "I'm canceling the meeting because I went to your website and the style doesn't suit me." What's your reply?`,
    answers: [
      { number: "A", isCorrect: false, text: `Ask them what they didn't like on the website and send them some new looks` },
      { number: "B", isCorrect: false, text: `Offer them to meet in person because everyone is different and we do custom clothing they'll like` },
      { number: "C", isCorrect: true, text: `Don't reply and just quietly delete them off your calendar` },
      { number: "D", isCorrect: false, text: `Confront them about how our website features literally the biggest celebrities in the world and if he can't trust a tailor who works at the elite global level then obviously he can't trust anyone so what's the problem with us?` }
    ],
    explanation: `Obviously, answer "D" is the truth- but they already know that. They're just trying to get out of the meeting and gaslighting the blame away from themselves because they are passive-aggressive and can't take ownership of their disagreements. Let them be; you don't want people like that in your life. No response to a manipulative gaslighter is the best response.`
  },
  {
    question: `You've booked a prospect but they reschedule. You've followed the script on the first response. They replied in the affirmative that they'd like to reschedule. You offer a time. They have not replied for 24 hours. What's the correct text to send next?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Just following up to see if you got my message?"` },
      { number: "B", isCorrect: false, text: `Copy and paste the previous message you sent them, again` },
      { number: "C", isCorrect: false, text: `Forget they exist and move on` },
      { number: "D", isCorrect: true, text: `"________ I dont want to keep repeating the same message and annoying you, so let me know if you’re planning to make this up, or if I should cross you off and move on. In the meantime, here are some cat emojis. 🐱🐱🐱"` }
    ],
    explanation: `It's right from our scripts. And it works because it's a good balance of confronting and deescalating through humour.`
  },
  {
    question: `When a prospect agrees to reschedule, what should you expect next from them when you offer them a time?`,
    answers: [
      { number: "A", isCorrect: false, text: `They will agree to the time you offer` },
      { number: "B", isCorrect: true, text: `They will try to get you to call them later` },
      { number: "C", isCorrect: false, text: `They will offer a different time to you than what you offered` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `Even after they agree to reschedule, expect another confrontation because they will try to get you to call them later to reschedule. That's why we have a script to follow because we know the pattern!`
  },
  {
    question: `You ask a prospect "do you want to reschedule or do you just want to cancel" and they reply with "just call me next week" - what should you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Call them next week` },
      { number: "B", isCorrect: false, text: `Forget they exist` },
      { number: "C", isCorrect: true, text: `Ask them again if they want to reschedule or just want to cancel` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `They never answered the question. Ask them again to get them back into the process. There is no point to chase them, and there's no point to continue without knowing if they actually want to reschedule. By avoiding your question, the prospect is being passive-aggressive. Repeat the question and let them either jump out or jump back into our scripts.`
  },
  {
    question: `You correctly answered question 9 and handled the situation appropriately. The prospect says "I was going to reschedule but now that you've written that, you're rude and I won't meet you!" What does this mean?`,
    answers: [
      { number: "A", isCorrect: true, text: `The prospect is gaslighting you because they can't say "no" and even though they obviously avoided your question in order to get you to do "pointless work" by calling them when they just want to avoid you, they won't take responsibility and are now just trying to make you feel bad to shift the blame away from their own passive-aggressive behaviour` },
      { number: "B", isCorrect: false, text: `I'm a clown` },
      { number: "C", isCorrect: false, text: `I'm a clown` },
      { number: "D", isCorrect: false, text: `I'm a clown` }
    ],
    explanation: `There are some (but not a lot of) pieces of crap out there who will try to gaslight you because they can't own their "no's" so they have to project their lack of basic human emotions onto you. Don't take it personally; they are there to be laughed at.`
  },
  {
    question: `Which meetings reschedule/cancel the least?`,
    answers: [
      { number: "A", isCorrect: false, text: `Cold Calls` },
      { number: "B", isCorrect: false, text: `Leveraged calls (same building)` },
      { number: "C", isCorrect: true, text: `Referrals` },
      { number: "D", isCorrect: false, text: `They all reschedule/cancel the same` }
    ],
    explanation: `The stronger the leverage, the less likely they'll cancel/reschedule. Referrals are the strongest leverage.`
  },
  {
    question: `What % of your unleveraged <u>cold-call</u> meetings will cancel/reschedule?`,
    answers: [
      { number: "A", isCorrect: true, text: `67%+` },
      { number: "B", isCorrect: false, text: `33%` },
      { number: "C", isCorrect: false, text: `10%` },
      { number: "D", isCorrect: false, text: `0%` }
    ],
    explanation: `Cold call bookings without leverage will cancel 2/3rds of the time (at least).`
  },
  {
    question: `What % of your <u>referral</u> meetings will cancel/reschedule?`,
    answers: [
      { number: "A", isCorrect: false, text: `67%+` },
      { number: "B", isCorrect: true, text: `33%` },
      { number: "C", isCorrect: false, text: `10%` },
      { number: "D", isCorrect: false, text: `0%` }
    ],
    explanation: `Referral meetings will fall off 1/3 of the time.`
  },
  {
    question: `What % of your referral meetings will reschedule after they fall off if you follow the scripts?`,
    answers: [
      { number: "A", isCorrect: true, text: `67%+` },
      { number: "B", isCorrect: false, text: `33%` },
      { number: "C", isCorrect: false, text: `10%` },
      { number: "D", isCorrect: false, text: `0%` }
    ],
    explanation: `Referral meetings that fall off will reschedule 2/3 of the time if you follow the scripts.`
  },
  {
    question: `What % of your unleveraged cold-call meetings will reschedule after they fall off if you follow the scripts?`,
    answers: [
      { number: "A", isCorrect: false, text: `67%+` },
      { number: "B", isCorrect: false, text: `33%` },
      { number: "C", isCorrect: true, text: `10%` },
      { number: "D", isCorrect: false, text: `0%` }
    ],
    explanation: `About 1/10 cold call meetings that fell off will reschedule if you follow the scripts. Leverage really matters for keeping appointments. That's why we focus so much on referrals and overall good leverage. It's still worth saving the 10% so follow the scripts!`
  },
  {
    question: `What's the most common time period people use to offer you to call them back to reschedule?`,
    answers: [
      { number: "A", isCorrect: false, text: `Next week` },
      { number: "B", isCorrect: false, text: `Next month` },
      { number: "C", isCorrect: false, text: `After (insert current holiday/current world event)` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `It doesn't matter when they tell you to call back, you're still a clown if you do it. Follow the scripts and confront them according to script! `
  }
];
