import { UserContextService } from '../../core/service/user-context.service';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { links } from 'src/app/core/links';
import { GlobalConstants } from 'src/app/core/global-constants';
import { LoginService } from 'src/app/core/service/login.service';
import { UserService } from 'src/app/core/service/user.service';
import { FormsModule } from '@angular/forms';
import { ProfileSettingsModalComponent } from '../profile-settings-modal/profile-settings-modal.component';
import { UserModel } from 'src/app/core/models/user.model';
import { NotificationComponent } from '../notification/notification.component';

@Component({
    selector: 'lgfg-header-desktop',
    templateUrl: './header-desktop.component.html',
    styleUrls: ['./header-desktop.component.scss'],
    standalone: true,
    imports: [RouterLink, FormsModule, NotificationComponent]
})
export class HeaderDesktopComponent implements OnInit {
    @Input() profileSettingsModal: ProfileSettingsModalComponent;

    public search: string;
    public links = links;
    public isDarkTheme = true;
    public isTechSupport: boolean;
    public hasAccessToSubmitPayment: boolean;
    public myGoalLink = links.myGoal;
    public isClother = this.context.isAdmin() || this.context.isRep();

    public user: UserModel;

    constructor(
        private context: UserContextService,
        private loginService: LoginService,
        private userService: UserService) { }

    ngOnInit(): void {
        this.user = this.context.user.value;
        this.isTechSupport = this.context.isTechSupport();
        this.hasAccessToSubmitPayment = this.context.isRep() || this.context.isAdmin() || this.context.isEA() || this.context.isAccounting();
        this.isDarkTheme = this.context.user.value?.isBlackTheme != undefined ? !this.context.user.value?.isBlackTheme : false;
        this.setTheme();
        this.context.avatarChange.subscribe(() => {
            this.user = this.context.user.value;
        });
    }

    public async logout(): Promise<void> {
        await this.loginService.logout();
    }

    public openHangfire() {
        document.cookie = GlobalConstants.jwtSessionKey + "=" + sessionStorage.getItem(GlobalConstants.jwtSessionKey);
        window.location.href = links.hangfireDashboard;
    }

    public async changeTheme() {
        this.setTheme();
        await this.userService.setUserTheme(this.context.user.value.id, !this.isDarkTheme);
    }

    private setTheme() {
        document.body.classList.add(!this.isDarkTheme ? 'dark-theme' : 'light-theme');
        document.body.classList.remove(!this.isDarkTheme ? 'light-theme' : 'dark-theme');
        this.context.setTheme(!this.isDarkTheme);
    }
}