export const phoneQuestions = [
  {
    question: `What is the <u>biggest mistake</u> you can make on the phone?`,
    answers: [
      { number: "A", isCorrect: false, text: `Not correctly identifying an Objection and using Confusion response` },
      { number: "B", isCorrect: false, text: `Not correctly identifying Confusion and using Objection response` },
      { number: "C", isCorrect: false, text: `Not Qualifying` },
      { number: "D", isCorrect: true, text: `Calling someone back or scheduling to call someone back who didn't book with you right there on the call` }
    ],
    explanation: `The biggest mistake you can ever make with your phone game is to believe someone when they say "call me back" and either schedule to call them back or actually do it. The success rate of booking someone you didn't book on the first call is close to 0%. If you don't book them on the call, take the "L," learn from it, and move on to call someone else.`
  },
  {
    question: `What is this response from a prospect called: "I don't need any suits right now"?`,
    answers: [
      { number: "A", isCorrect: false, text: `Confusion` },
      { number: "B", isCorrect: true, text: `Objection` },
      { number: "C", isCorrect: false, text: `Negotiation` },
      { number: "D", isCorrect: false, text: `Confrontation` }
    ],
    explanation: `This is an Objection because the product was directly stated from the prospect.`
  },
  {
    question: `What is this response from a prospect called: "I'm not the person in the company who handles this"?`,
    answers: [
      { number: "A", isCorrect: true, text: `Confusion` },
      { number: "B", isCorrect: false, text: `Objection` },
      { number: "C", isCorrect: false, text: `Negotiation` },
      { number: "D", isCorrect: false, text: `Confrontation` }
    ],
    explanation: `This is a confusion because the product is not directly stated from the prospect.`
  },
  {
    question: `What is this response from a prospect called: "When do you plan on being by my office?"`,
    answers: [
      { number: "A", isCorrect: false, text: `Confusion` },
      { number: "B", isCorrect: false, text: `Objection` },
      { number: "C", isCorrect: true, text: `Negotiation` },
      { number: "D", isCorrect: false, text: `Confrontation` }
    ],
    explanation: `This is a negotiation because you can move forward and close.`
  },
  {
    question: `Which one of these answers correctly solves the following problem:<br>You call a prospect named "Mike" who tells you that he is not in the market for suits at the moment. You (correctly) identify this answer confusion&#8203;/objection&#8203;/negotiation&#8203;/confrontation based on the training you've received, and you reply accordingly. The prospect then says "can you send me an email"`,
    answers: [
      { number: "A", isCorrect: false, text: `"Mike, Let me back up, I guess you haven't heard about me yet, what I do is I come right into the office and I make..."` },
      { number: "B", isCorrect: true, text: `"Mike, you know I sell suits, why would I send you an email to tell you the same as that would be redundant, wouldn't it..."` },
      { number: "C", isCorrect: false, text: `"Mike, I don't want to replace your entire wardrobe and sell you things you don't need, I wanted to take a moment..."` },
      { number: "D", isCorrect: false, text: `"Can you open your calendar"` }
    ],
    explanation: `This is a confrontation because it's a fake confusion; that is, the prospect has already explicitly stated the product, so clearly by asking for more information or a call back is a way to get you off the phone; at this point it's appropriate to call him out and level the status with a confrontation.`
  },
  {
    question: `Identify the following response from a prospect: "I appreciate the call but I have it well taken care of!"`,
    answers: [
      { number: "A", isCorrect: true, text: `Confusion` },
      { number: "B", isCorrect: false, text: `Objection` },
      { number: "C", isCorrect: false, text: `Negotiation` },
      { number: "D", isCorrect: false, text: `Confrontation` }
    ],
    explanation: `This is confusion because the prospect did not directly state the product.`
  },
  {
    question: `How do you correctly handle confusion?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Can you open your calendar"` },
      { number: "B", isCorrect: true, text: `"Let me back up.."` },
      { number: "C", isCorrect: false, text: `"I don't want to sell you a bunch of ..."` },
      { number: "D", isCorrect: false, text: `"Why would I..."` }
    ],
    explanation: `The correct way to handle a confusion is always to just back up and repeat what you're selling.`
  },
  {
    question: `Psychologically, which has the highest likelihood of increasing the prospect's interest in taking a meeting to see the product you're selling?`,
    answers: [
      { number: "A", isCorrect: false, text: `General interest in the product itself` },
      { number: "B", isCorrect: true, text: `The leverage` },
      { number: "C", isCorrect: false, text: `Time of your call` },
      { number: "D", isCorrect: false, text: `Need to purchase the product` }
    ],
    explanation: `While it's nice that someone might want or even need your product, you're not the only one on the market, and you're a new person in their life, so their trust for you is low. For a prospect to seriously consider you as an option, therefore, the product is not as important as the trust in your ability to deliver said product satisfactorily. Therefore, the leverage is the biggest determinant of whether they'll take the meeting.`
  },
  {
    question: `If you're getting a lot of "confusion" answers on the phone, which is the likeliest cause?`,
    answers: [
      { number: "A", isCorrect: false, text: `Lack of interest in the product` },
      { number: "B", isCorrect: false, text: `The leverage` },
      { number: "C", isCorrect: true, text: `You just sound bad with your tone, pace and annunciation` },
      { number: "D", isCorrect: false, text: `It's normal for good salespeople to get lots of confusion responses` }
    ],
    explanation: `Confusion response is a natural response to bad-sounding salespeople. You need to work on your tone, pace and annunciation.`
  },
  {
    question: `If you're getting a lot of "objection" answers on the phone, which is the likeliest cause?`,
    answers: [
      { number: "A", isCorrect: false, text: `Lack of interest in the product` },
      { number: "B", isCorrect: true, text: `The leverage` },
      { number: "C", isCorrect: false, text: `You just sound bad with your tone, pace and annunciation` },
      { number: "D", isCorrect: false, text: `It's normal for good salespeople to get lots of confusion responses` }
    ],
    explanation: `Leverage increases a person's interest to see your product, and since you're getting real answers, unlike confusion, you're probably starting to sound pretty good in your tone etc.`
  },
  {
    question: `Which is the correct way to handle a prospect when his response to your approach is "I'm in the  office Tuesdays and Thursdays, are you planning on being here either of those days?"`,
    answers: [
      { number: "A", isCorrect: false, text: `"I have some time available next Tuesday or Thursday, which is better for you?"` },
      { number: "B", isCorrect: true, text: `"Can you open your calendar"` },
      { number: "C", isCorrect: false, text: `"I can make Thursday work, how is 5pm?"` },
      { number: "D", isCorrect: false, text: `"What time works for you on those days?"` }
    ],
    explanation: `This is a negotiation and a negotiation has only one answer.`
  },
  {
    question: `A prospect responds to your first phone approach with "I will meet you but I don't plan on buying anything"; what is the correct response?`,
    answers: [
      { number: "A", isCorrect: true, text: `"Can you open your calendar"` },
      { number: "B", isCorrect: false, text: `"That's not a problem, you can look at what I have.."` },
      { number: "C", isCorrect: false, text: `"Well, then let's not meet"` },
      { number: "D", isCorrect: false, text: `"Are you open to the idea of buying something if you like it?"` }
    ],
    explanation: `This is a negotiation and a negotiation has only one answer. You can qualify after they're booked.`
  },
  {
    question: `You close the negotiation, and the prospect agrees to meet you 10am on Thursday. What's the next thing that you should say?`,
    answers: [
      { number: "A", isCorrect: false, text: `"A couple of questions before we meet..."` },
      { number: "B", isCorrect: true, text: `"Let me give you my name and number so that you have it written in your calendar. Let me know when you're ready to write"` },
      { number: "C", isCorrect: false, text: `"Before we meet, a couple of questions to save time.."` },
      { number: "D", isCorrect: false, text: `"Let me give you my name and number"` }
    ],
    explanation: `If you don't say "let me know when you're ready to write" then it's much more likely that the meeting won't happen because the prospect won't write it into their calendar.`
  },
  {
    question: `You make your phone approach, and the prospect says "sure I can meet you, send me an email when you're here" - what's the correct response?`,
    answers: [
      { number: "A", isCorrect: true, text: `"Can you open your calendar"` },
      { number: "B", isCorrect: false, text: `"Sure and before we meet, a few questions.."` },
      { number: "C", isCorrect: false, text: `"I'm not just going to show up at your office like gypsy..."` },
      { number: "D", isCorrect: false, text: `"Well let's find a time right now..."` }
    ],
    explanation: `This is a negotiation and a negotiation has only one answer.`
  },
  {
    question: `You correctly handle the situation above, and the prospect once again replies: "just let me know when you're here"; what's the correct response:`,
    answers: [
      { number: "A", isCorrect: false, text: `"Can you open your calendar"` },
      { number: "B", isCorrect: false, text: `"Sure and before we meet, a few questions.."` },
      { number: "C", isCorrect: true, text: `"I'm not just going to show up at your office like gypsy..."` },
      { number: "D", isCorrect: false, text: `"Well let's find a time right now..."` }
    ],
    explanation: `Since you've already correctly handled the first close, at this point you have to confront it and then close again- the confrontation script is the one with the gypsy :)`
  },
  {
    question: `You leave a VM, and the prospect calls you back. What's the correct way to handle the call?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Thanks for calling me back. The reason I called is..."` },
      { number: "B", isCorrect: false, text: `"Can you call me later?"` },
      { number: "C", isCorrect: false, text: `"Can we schedule a time for me to call you later?"` },
      { number: "D", isCorrect: true, text: `"I'm just in the elevator right now, can I call you back in a minute?"` }
    ],
    explanation: `You gain status by making them wait for you and it gives you time to look at the lead to see who called, and what the leverage is.`
  },
  {
    question: `Which leverage is the strongest?`,
    answers: [
      { number: "A", isCorrect: true, text: `Direct referral` },
      { number: "B", isCorrect: false, text: `Names in the same office` },
      { number: "C", isCorrect: false, text: `Names in the same building` },
      { number: "D", isCorrect: false, text: `Names in the same company but different office` }
    ],
    explanation: `Direct referral is the strongest leverage, that's why we learn the referral script early.`
  },
  {
    question: `Which of the above counts as "leverage"?`,
    answers: [
      { number: "A", isCorrect: false, text: `Common clients on Linkedin` },
      { number: "B", isCorrect: false, text: `Clients in their office` },
      { number: "C", isCorrect: false, text: `Meeting room names in their office` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `All of the above.`
  },
  {
    question: `What is absolutely required for a call to count as a QB from the qualifying questions?`,
    answers: [
      { number: "A", isCorrect: false, text: `You are written in the client's calendar` },
      { number: "B", isCorrect: false, text: `Client identified a specific item to shop for` },
      { number: "C", isCorrect: false, text: `Client is easily price qualified` },
      { number: "D", isCorrect: false, text: `Client knows they're in a buying situation where your intent is to sell them on the spot, when you meet` },
      { number: "E", isCorrect: true, text: `All of the above` }
    ],
    explanation: `All of the above have to be met for it to be a QB.`
  },
  {
    question: `If a client answers the price qualifying question with "that's more than I usually spend," what's the <u>best</u> response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Would you spend more if my quality and service are better?"` },
      { number: "B", isCorrect: false, text: `"I'm sure you're not buying anything quite this nice, so maybe try it"` },
      { number: "C", isCorrect: true, text: `"Does it make sense to meet or should we just cross this off then?"` },
      { number: "D", isCorrect: false, text: `"Well you don't have to buy it when we meet!"` }
    ],
    explanation: `In luxury sales, we don't want to justify the price. It's understood that we're an expensive option because we're a premium service reserved for high achievers. This service is not for everyone. Nobody goes to the Ferrari dealership for an "economically priced car" and we're OK with that. Therefore, when confronted, we don't need to justify ourselves and can politely offer not to proceed forward so we save everyone's time and find prospects who are ready for this service.`
  },
  {
    question: `You give the last qualifying (ie Buying) question, and the client says "it depends on the quality." What is the best response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"I guarantee the quality is good"` },
      { number: "B", isCorrect: true, text: `"You won't know the quality until after you've bought it and worn it for many years. Are you willing to risk buying when we meet so you can test the quality?"` },
      { number: "C", isCorrect: false, text: `"Does it make sense to meet or should we just cross this off then?"` },
      { number: "D", isCorrect: false, text: `"I will explain everything about the quality when we meet!"` }
    ],
    explanation: `It's understood that nobody knows the quality of a custom product until after it's used. That's a risk the client needs to know before the meeting and we should be open about that risk.`
  },
  {
    question: `When you ask which items the prospect is shopping for (suits, jackets, shirts, chinos), they answer "I usually wear suits." What's the correct way to proceed?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Great, I will prepare some suits"` },
      { number: "B", isCorrect: true, text: `"Should I prepare suits for you then, or were you thinking of some shirts or business casual to go with the suits you have?"` },
      { number: "C", isCorrect: false, text: `"How many suits are you thinking of adding?"` },
      { number: "D", isCorrect: false, text: `"What's the next suit you were thinking to add or replace?"` }
    ],
    explanation: `A bit of a tricky situation. The prospect didn't answer your question. You asked what they're shopping for and the prospect responded with what they usually wear (this is not what you asked!) - the correct way to proceed is to rephrase the same question so that they answer it. This way the prospect will understand that this is a buying situation not an "information session."`
  },
  {
    question: `The prospect has identified that they are indeed shopping for suits. You ask the prospect "of your basic suit colours, grey, blue, black and stripe, what's the next suit you will add or replace?" and the prospect replies with "I usually wear grey." What's the correct way to proceed?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Great, I will prepare grey suits"` },
      { number: "B", isCorrect: false, text: `"How many grey suits do you need to replace?"` },
      { number: "C", isCorrect: false, text: `"Are you thinking of a solid grey for your next suit or something with a pattern?"` },
      { number: "D", isCorrect: true, text: `"Are you thinking your next suit you'll buy will be grey, or something else?"` }
    ],
    explanation: `A bit of a tricky situation. The prospect didn't answer your question. You asked what they're shopping for and they answered what they usually wear. The correct way to proceed is to rephrase the same question so that they answer it. This way the prospect will understand that this is a buying situation not an "information session."`
  },
  {
    question: `You booked the meeting and the prospect doesn't show up. What's the <u>most likely</u> reason this happened, from what you can control?`,
    answers: [
      { number: "A", isCorrect: true, text: `You didn't say <i>"let me know when you're ready to write"</i> once they had their calendar open` },
      { number: "B", isCorrect: false, text: `You booked the meeting for a Friday` },
      { number: "C", isCorrect: false, text: `You didn't qualify properly` },
      { number: "D", isCorrect: false, text: `Prospect was busy that day` }
    ],
    explanation: `Most common reason prospects don't show up is they simply didn't write you into the calendar. You likely forgot to say "let me know when you're ready to write" and the prospect just thought they'd "remember" the meeting on the day or they'd be in the office. Magically, when they don't WRITE you into the calendar, they never happen to be there.`
  },
  {
    question: `You go to the meeting and the prospect says "I just wanted to learn about the company but I'm not buying anything today."<br>What's the most likely reason this happened, from what you can control?`,
    answers: [
      { number: "A", isCorrect: true, text: `Prospect did not clearly understand this meeting was to make a purchase/ poor qualifying` },
      { number: "B", isCorrect: false, text: `The prospect got busy and forgot that you were coming` },
      { number: "C", isCorrect: false, text: `You forgot to remind the prospect about the upcoming meeting` },
      { number: "D", isCorrect: false, text: `Prospect will probably buy if you show them enough fabrics` }
    ],
    explanation: `Prospect clearly did not show up to buy. Qualifying sets the prospect up to buy clothing because proper qualifying has a specific item, an agreed upon price range, and the understanding that the meeting is set up for the prospect to make a purchase. The purpose of the meeting is to buy clothes. You communicated this poorly with qualifying.`
  },
  {
    question: `You're on the call and the prospect says "I will bring some friends to the meeting with me!" What does this mean?`,
    answers: [
      { number: "A", isCorrect: false, text: `Hooray, more clients!` },
      { number: "B", isCorrect: false, text: `The prospect wants to bring you more clients on the spot` },
      { number: "C", isCorrect: true, text: `The prospect clearly doesn't understand that this is a sales meeting` },
      { number: "D", isCorrect: false, text: `The prospect really wants to buy from you` }
    ],
    explanation: `Men don't buy clothes in roaming wolf packs. The prospect clearly thinks this is "informational" and now would be a good time to confront that using our scripts.`
  },
  {
    question: `You have sent a calendar invite and it's been 48 hours but the new prospect hasn't accepted it. What should you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Nothing. Wait for the meeting to come and just show up` },
      { number: "B", isCorrect: false, text: `Call 1 day before the meeting and ask if it's still on` },
      { number: "C", isCorrect: true, text: `Call after 48 hours and confront` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `You should call and ask if they're planning to accept the invite since you have to manage your trip.`
  },
  {
    question: `You get a prospect to write you into the calendar but they don't let you qualify them and say "let's just do this when you're here." What's the correct approach?`,
    answers: [
      { number: "A", isCorrect: false, text: `Qualify them when you meet them in person` },
      { number: "B", isCorrect: false, text: `Email them qualification questions` },
      { number: "C", isCorrect: true, text: `"Quick qualify" with the 2 question script` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `You should in this situation ONLY, quick qualify with the 2 question script.`
  },
  {
    question: `You get a prospect to agree to meet, but when you ask to write you into the calendar, they say "send me an invite!" What's the correct approach?`,
    answers: [
      { number: "A", isCorrect: true, text: `"I'll definitely do that but let me give you my name and number so you have it in your calendar. Let me know when you're ready to write"` },
      { number: "B", isCorrect: false, text: `Agree and qualify` },
      { number: "C", isCorrect: false, text: `"Will you accept the invite?"` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `It's too early to confront that with "C" - right now you should just ask again to write you into the calendar.`
  },
  {
    question: `You call a prospect and you hear they're driving before you even approach. What's the best way forward?`,
    answers: [
      { number: "A", isCorrect: false, text: `Proceed as normal` },
      { number: "B", isCorrect: true, text: `Hang up and call them the next day` },
      { number: "C", isCorrect: false, text: `Ask them first if this is a good time to talk` },
      { number: "D", isCorrect: false, text: `Ask them to pull over` }
    ],
    explanation: `Best to call when they're not driving. If it comes out later during the call that they are driving, there are then more advanced ways to handle that, but it's not an ideal situation.`
  },
  {
    question: `Before you book a prospect on the calendar, without them writing you in, the prospect on the phone says they're losing weight and will not buy suits right now. What's the best way forward?`,
    answers: [
      { number: "A", isCorrect: false, text: `Ask them when they'll be in the right shape to buy new suits and call them then` },
      { number: "B", isCorrect: false, text: `Explain that suits can be altered up to 10KG in weight change and see if there's some other things they may wish to replenish like shirts or shoes` },
      { number: "C", isCorrect: true, text: `Treat as an objection` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `This is just an objection because you haven't booked them yet, and they have stated your product so it's not a confusion.`
  },
  {
    question: `After you book a prospect on the calendar, with them writing you in, the prospect on the phone says they're losing weight and will not buy suits right now. What's the best way forward?`,
    answers: [
      { number: "A", isCorrect: false, text: `Ask them when they'll be in the right shape to buy new suits and call them then` },
      { number: "B", isCorrect: true, text: `Explain that suits can be altered up to 10KG in weight change and see if there's some other things they may wish to replenish like shirts or shoes` },
      { number: "C", isCorrect: false, text: `Treat as an objection` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `This is after the prospect has written you into the calendar, so it's likely they're telling the truth. You can explain that suits can be altered up to 10KG in body weight. It doesn't mean that they'll be qualified, but it's the best approach from our end.`
  },
  {
    question: `You believed a prospect to call them back later because they were losing weight, or changing jobs, or they were just busy the first time you called. You call the prospect back. What are you?`,
    answers: [
      { number: "A", isCorrect: false, text: `Good at customer service` },
      { number: "B", isCorrect: false, text: `Good at sales` },
      { number: "C", isCorrect: false, text: `Smarter than anyone at LGFG` },
      { number: "D", isCorrect: true, text: `A clown` }
    ],
    explanation: `Congratulations. You broke the most important rule. Prepare for the prospect to make you feel bad about yourself.`
  },
  {
    question: `A prospect on the phone will not write you into the calendar, but insists that you send him an email with some time options and he will reply and pick a time that works. You agree to do that. What's the most likely thing to happen when you email him time options?`,
    answers: [
      { number: "A", isCorrect: true, text: `Prospect tricked you into believing a lie and now you're a clown because he will never reply and you did free work for nothing` },
      { number: "B", isCorrect: false, text: `Prospect was genuinely busy and since you've kept your promise, he will keep his and choose a time that works for both of you` },
      { number: "C", isCorrect: false, text: `Prospect may not like any of the times you've offered, but will nonetheless suggest an alternative time to meet` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `Congratulations. You broke the most important rule. Prepare for the prospect to make you feel bad about yourself.`
  },
  {
    question: `Prospect fundamentally agrees to meet but at the time of opening his calendar, he says he's driving. What's the correct response here?`,
    answers: [
      { number: "A", isCorrect: false, text: `Offer to send a calendar invite` },
      { number: "B", isCorrect: false, text: `Offer to send an email with different time options` },
      { number: "C", isCorrect: true, text: `Ask him to pull over and put you on speaker so he can open his calendar while you wait` },
      { number: "D", isCorrect: false, text: `Ask him if he knows his schedule off the top of his head` }
    ],
    explanation: `If you are not written into the calendar, the prospect will forever disappear into the abyss.`
  },
  {
    question: `What's the most common way in which weak and passive-aggressive people say "no"`,
    answers: [
      { number: "A", isCorrect: false, text: `They just say "no"` },
      { number: "B", isCorrect: true, text: `They ask you to come back to them later somehow in order to get you off their back right now, in hopes that you never follow through, and if and when you do, they avoid and ignore you` },
      { number: "C", isCorrect: false, text: `They scream and yell at you` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `The most common way passive-aggressive people say "no" is by asking you to call later, to email them, or to otherwise do some free work that will tie you up for some time, so that they don't have to say "no" now. If you do the work and come back to them later, then you'll very likely be screamed at because they will gaslight you and make it "your fault" that you're wasting their time. That's why you need to learn to tactically confront so that you're not left with an egg on your face.`
  },
  {
    question: `What should you do immediately after you make a successful booking?`,
    answers: [
      { number: "A", isCorrect: false, text: `Review the call with your leader` },
      { number: "B", isCorrect: true, text: `Call the next prospect` },
      { number: "C", isCorrect: false, text: `Take a short break to appreciate your victory` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `After a sale or a booking, you have "the magic glow." Don't waste it! Bookings and sales often come in bundles and streaks because of the magic glow! As soon as you have a victory, NEVER TAKE A BREAK; call your next prospect right away and use the magic glow!`
  },
  {
    question: `The top salespeople know that they can't survive without:`,
    answers: [
      { number: "A", isCorrect: false, text: `Hitting the contacts each and every day` },
      { number: "B", isCorrect: false, text: `Knowing the correct way to handle the situations we encounter on the phone every day, according to our training` },
      { number: "C", isCorrect: false, text: `Having good habits outside of work` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `All of the above. You can't cheat the Universal laws. Any shortcut you try to take will only end up hurting you. Do the right thing.`
  },
  {
    question: `During Booking, the prospect asks "how long will the meeting take?"<br>What's the correct response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"It's just a few minutes"` },
      { number: "B", isCorrect: false, text: `"We'll keep it short and I promise not to waste your time!"` },
      { number: "C", isCorrect: true, text: `"If I'm there for more than 10 minutes it's because you want me there"` },
      { number: "D", isCorrect: false, text: `"About half an hour"` }
    ],
    explanation: `This is from our scripts.`
  },
  {
    question: `During Booking, the prospect says "don't come just for me" - what's the correct response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Of course not, I have other meetings too!"` },
      { number: "B", isCorrect: false, text: `"OK"` },
      { number: "C", isCorrect: true, text: `"If I'm there for more than 10 minutes it's because you want me there"` },
      { number: "D", isCorrect: false, text: `"Don't be mean to me, please"` }
    ],
    explanation: `This reply also works in this situation.`
  },
  {
    question: `You are DQ'ing nearly all of your bookings. What's the most likely cause?`,
    answers: [
      { number: "A", isCorrect: false, text: `The market you're in is too cheap` },
      { number: "B", isCorrect: false, text: `You have bad prospects` },
      { number: "C", isCorrect: true, text: `You are not skilled enough yet to book the kind of people who can afford our service` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `It's easier to "book" low end people because they are more agreeable and require less competence to deal with. As you get more competent, you will be able to book better people who can afford you.`
  },
  {
    question: `A prospect lectures you on the phone that you're "too expensive for the market." What should you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Change markets` },
      { number: "B", isCorrect: false, text: `Take the prospect's highly educated feedback seriously because he's an expert` },
      { number: "C", isCorrect: false, text: `Tell him he doesn't make enough money` },
      { number: "D", isCorrect: true, text: `Ignore the clown and politely move on to call on someone less arrogant and with more money` }
    ],
    explanation: `Poor clowns love to turn you into poor clowns. Do not get infected by their clown bites.`
  },
  {
    question: `You've been on the phone for 2 months and you're not an expert appointment booker yet. What's wrong?`,
    answers: [
      { number: "A", isCorrect: false, text: `You're slow` },
      { number: "B", isCorrect: false, text: `The Market Sucks` },
      { number: "C", isCorrect: true, text: `It's delusional to think that you can become an expert at anything in 2 months` },
      { number: "D", isCorrect: false, text: `All of the above` }
    ],
    explanation: `We wouldn't hire you if you were slow and we wouldn't have you in the market you're in if it sucked. You're new. This is life. Eat it with a smile and get better every call!`
  },
  {
    question: `Which of the below has the highest impact on a prospect's interest in your product:`,
    answers: [
      { number: "A", isCorrect: true, text: `Other people they know who buy the product` },
      { number: "B", isCorrect: false, text: `Their need for the product right now` },
      { number: "C", isCorrect: false, text: `Celebrities who endorse the product` },
      { number: "D", isCorrect: false, text: `The price of the product` }
    ],
    explanation: `Social Proof: Personally knowing other people who buy your product is by far the most influential factor on peoples' decision making.`
  },
  {
    question: `A prospect trusts <u>your product</u> more when you:`,
    answers: [
      { number: "A", isCorrect: false, text: `Work for a small company` },
      { number: "B", isCorrect: true, text: `Work for a very big company` },
      { number: "C", isCorrect: false, text: `Work for yourself` },
      { number: "D", isCorrect: false, text: `Sell the cheapest product` }
    ],
    explanation: `Social Proof: People naturally mitigate risk by buying from suppliers that the market has already picked to be the winner.`
  },
  {
    question: `You have a referral from Dave to call his friend Steve. You don't have Steve's direct number but you have his company number. When you call, the receptionist is asking you what the call is about. What's the correct response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"I'm a tailor and I'd like to schedule a meeting"` },
      { number: "B", isCorrect: false, text: `"It's about tailoring"` },
      { number: "C", isCorrect: true, text: `"Please tell him that Dave told me to call him. Thanks"` },
      { number: "D", isCorrect: false, text: `"it's a personal business matter"` }
    ],
    explanation: `This is the script to get past a gatekeeper when you have a direct referral.`
  },
  {
    question: `Which of the below is the best VM to leave for a referral?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Hi Steve, this is Dimitry, please give me a call back. My number is... again, my number is..."` },
      { number: "B", isCorrect: true, text: `"Hi Steve, this is Dimitry. Dave Porter said I should give you a call. My number is... again, my number is..."` },
      { number: "C", isCorrect: false, text: `"Hi Steve, this is Dimitry, I had a quick question for you, please call me. My number is... again my number is..."` },
      { number: "D", isCorrect: false, text: `"Hi Steve, this is Dimitry, Dave Porter referred you. Please call me. My number is... again my number is..."` }
    ],
    explanation: `This is the VM script for referrals.`
  },
  {
    question: `Which of the below is the best VM to leave for a cold-call?`,
    answers: [
      { number: "A", isCorrect: true, text: `"Hi Steve, this is Dimitry, I had a quick question for you. My number is... again my number is..."` },
      { number: "B", isCorrect: false, text: `"Hi Steve, this is Dimitry. I'm a tailor and I'm coming to your office. My number is... again, my number is..."` },
      { number: "C", isCorrect: false, text: `"Hi Steve, this is Dimitry, I had a quick question for you about your suits, please call me. My number is... again my number is..."` },
      { number: "D", isCorrect: false, text: `"Hi Steve, this is Dimitry. I wanted to book an appointment with you. Please call me. My number is... again my number is..."` }
    ],
    explanation: `This is the cold call script for VM. Less info on VM is better!`
  },
  {
    question: `Which one of these will always lead to bad outcomes?`,
    answers: [
      { number: "A", isCorrect: false, text: `Calling someone back who you didn't book right there and then, but they asked you to call them back` },
      { number: "B", isCorrect: false, text: `Sending someone "more information" because they asked you to send more information` },
      { number: "C", isCorrect: false, text: `Sending someone an email because they asked you to send them an email` },
      { number: "D", isCorrect: true, text: `All of the above` }
    ],
    explanation: `These will all lead to you being a clown.`
  },
  {
    question: `How should you address people you call on?`,
    answers: [
      { number: "A", isCorrect: false, text: `By their pronoun` },
      { number: "B", isCorrect: true, text: `By their first name` },
      { number: "C", isCorrect: false, text: `By their honorific (Mr/Dr)` },
      { number: "D", isCorrect: false, text: `Any of the above` }
    ],
    explanation: `Address prospects and clients by their first name only.`
  },
  {
    question: `How long should it take you to really figure out the phone?`,
    answers: [
      { number: "A", isCorrect: false, text: `1 month` },
      { number: "B", isCorrect: false, text: `6 months` },
      { number: "C", isCorrect: false, text: `12 months` },
      { number: "D", isCorrect: true, text: `Everyone figures it out at a different pace` }
    ],
    explanation: `It might take you a month or it might take you 12 months to feel like you're really good on the phone. Follow the process and trust the system- you're not the first and you won't be the last to figure out great calling that gets QB's!`
  },
  {
    question: `When someone is being strangely friendly to you on the phone right away before they even know what you're calling about, what might you suspect?`,
    answers: [
      { number: "A", isCorrect: true, text: `They're not qualified to buy our product` },
      { number: "B", isCorrect: false, text: `They hate their job and are happy you got them away from it for a minute` },
      { number: "C", isCorrect: false, text: `They're weird` },
      { number: "D", isCorrect: false, text: `None of the above` }
    ],
    explanation: `You'll figure out very quickly that people who lack the natural skepticism of a stranger calling them are probably afraid to be offensive and therefore are overly polite to cover their fear. Those people typically are not making important decisions and therefore are not the types of income earners who are qualified for our offering.`
  },
  {
    question: `A prospect is telling you that they are extremely interested in buying some suits, in fact they are already imagining getting 10 suits from you, but they just can't write you in the calendar right now, so won't you please just email them. You can smell the sale... What should you do?`,
    answers: [
      { number: "A", isCorrect: false, text: `Send the damn email, they want to buy 10 suits and they promise they want to buy from you!` },
      { number: "B", isCorrect: false, text: `Call them back later when it's a better time and book them!` },
      { number: "C", isCorrect: true, text: `If you don't book them right there on the call, consider them dead. Take it and move on!` },
      { number: "D", isCorrect: false, text: `Surely they wouldn't lie to you and be so convincing! Just show up at their office and ask for them next time you're there!` }
    ],
    explanation: `Prospects will try very hard to trick you into doing free work like emailing them or calling them again. If they refuse to do the smallest thing now, to write you into their calendar, regardless of how convincing they sound, they are just trying to get rid of you. Don't be a clown. DO NOT call back people, schedule to call back people or email people who didn't book with you. They're dead. Move on!!`
  }
];
