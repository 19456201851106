import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { links } from 'src/app/core/links';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserContextService } from 'src/app/core/service/user-context.service';
import { UserRoleEnum } from 'src/app/core/enums/user-role.enums';
import { NavTabEnum } from 'src/app/core/enums/nav-tab.enum';
import { NgClass } from '@angular/common';
import { GlobalConstants } from 'src/app/core/global-constants';
import { environment } from 'src/environments/environment';
import { ArrayUtil } from 'src/app/core/utility/array.util';
import { filter } from 'rxjs';

@Component({
    selector: 'sidebar-desktop',
    templateUrl: './sidebar-desktop.component.html',
    styleUrls: ['./sidebar-desktop.component.scss'],
    standalone: true,
    imports: [RouterLink, FormsModule, NgClass]
})
export class SideBarDesktopComponent implements OnInit {
    @Output() public openSideBar: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("dropdownMenuBody", { static: true }) public dropdownBody: any;
    @ViewChild("dropdownMenu", { static: true }) public dropdown: any;

    public links = links;
    public role: UserRoleEnum;
    public documentLink = { name: 'Documents', href: links.documentsLink + '/' + links.documents.List };
    public wardrobeCardLink = { name: 'Wardrobe Cards', href: links.wardrobeCardsLink + '/' + links.wardrobeCards.List };
    public actionLinks: ExtendedNavLink[] = [];
    public reportLinks: ExtendedNavLink[] = [];
    public adminSettings: ExtendedNavLink[] = null;
    public userName: string;
    public tabInFocus: NavTabEnum = 0;
    public enum = NavTabEnum;
    public currentPage: string;
    public isOpen = true;
    public isAdmin: boolean;
    public isCfo: boolean;
    public adminUrl = links.adminSettingsLink + '/' + links.adminSettings.mainPage;
    public isNumber = (value: any) => !Number.isNaN(Number(value));

    public onReportClick(link: ExtendedNavLink): void {
        link.isSelected = !link.isSelected;
    }

    constructor(private context: UserContextService, private router: Router) { }

    public ngOnInit(): void {
        this.isAdmin = this.context.isAdmin();
        this.isCfo = this.context.isCfo();
        this.isOpen = localStorage.getItem(GlobalConstants.isOpenSideBarKey) == 'true';
        this.openSideBar.emit(this.isOpen);
        this.setLinks();
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((val: any) => {
            if (val.url.includes("/SubmitPayment/") || val.url.includes("/MyGoal")) {
                this.setSideBarState();
            }
        });
    }

    public ngAfterContentInit(): void {
        this.setSideBarState();
    }

    public reportTabClick() {
        if (this.tabInFocus == 1 && this.isOpen) {
            this.tabInFocus = -1;
            return;
        }
        this.openSidebar();
        this.tabInFocus = 1;
    }

    public dashboardTabClick() {
        document.title = 'CMS 2.0';
        this.tabInFocus = 0;
        this.currentPage = "";
    }

    public actionsTabClick() {
        if (this.tabInFocus == 2 && this.isOpen) {
            this.tabInFocus = -1;
            return;
        }
        this.openSidebar();
        this.tabInFocus = 2;
    }

    public administrationTabClick() {
        if (this.tabInFocus == 5 && this.isOpen) {
            this.tabInFocus = -1;
            return;
        }
        this.openSidebar();
        this.tabInFocus = 5;
    }

    public documentsTabClick() {
        if (this.tabInFocus == 4) {
            return;
        }
        this.currentPage = "";
        this.tabInFocus = 4;
    }

    public wardrobeCardTabClick() {
        if (this.tabInFocus == 6) {
            return;
        }
        this.currentPage = "";
        this.tabInFocus = 6;
    }

    public clickOnLink(link: ExtendedNavLink) {
        this.currentPage = link.href;
    }

    public toggleSidebar() {
        this.isOpen = !this.isOpen;
        localStorage.setItem(GlobalConstants.isOpenSideBarKey, this.isOpen.toString());
        this.openSideBar.emit(this.isOpen);
    }

    private openSidebar() {
        if (this.isOpen) {
            return;
        }

        this.isOpen = true;
        localStorage.setItem(GlobalConstants.isOpenSideBarKey, this.isOpen.toString());
        this.openSideBar.emit(this.isOpen);
    }

    private setLinks(): void {
        const roles = this.context.getRoles();
        if (this.context.isAccounting()) {
            this.setAccountingLinks();
            return;
        }
        if (this.context.isAdmin()) {
            this.setAdminLinks();
            return;
        }
        roles.forEach(role => {
            if (role == UserRoleEnum.ea) {
                this.setEaLinks();
            }
            if (role == UserRoleEnum.sm) {
                this.setSmLinks();
            }
            if (role == UserRoleEnum.rep) {
                this.setRepLinks();
            }
            if (role == UserRoleEnum.alterationApprover) {
                this.reportLinks.push({ name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport });
            }
            if (role == UserRoleEnum.expenseApprover) {
                this.reportLinks.push({ name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport });
            }
            if (role == UserRoleEnum.expenseSubmiter) {
                this.reportLinks.push({ name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport });
            }
        });
        if (this.actionLinks.length == 0) {
            this.actionLinks = null;
        }

        this.reportLinks = ArrayUtil.distinct(this.reportLinks, 'name');
        this.actionLinks = ArrayUtil.distinct(this.actionLinks, 'name');
    }

    private setAdminLinks(): void {
        this.reportLinks = [
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
            { name: 'My Items Report', href: links.reportLink + '/' + links.reports.itemsReport },
            { name: 'My Cashflow Report', href: links.reportLink + '/' + links.reports.cashflowReport },
            { name: 'TotalNCPBank Report', href: links.reportLink + '/' + links.reports.totalNCPBankReport },
            { name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport },
            { name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport },
            { name: 'My Payments Report', href: links.reportLink + '/' + links.reports.paymentReport },
            { name: 'Rep Overdue Report', href: links.reportLink + '/' + links.reports.overdueReport },
            { name: 'Management Report', href: links.reportLink + '/' + links.reports.managementReport },
            { name: 'Rep Overview Report', href: links.reportLink + '/' + links.reports.overviewReport },
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Top Clients Report', href: links.reportLink + '/' + links.reports.topClientReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport },
            { name: 'Top Service Report', href: links.reportLink + '/' + links.reports.topServiceReport },
            { name: 'Remake Item Report', href: links.reportLink + '/' + links.reports.remakeItemReport },
            { name: 'Profit Loss Report', href: links.reportLink + '/' + links.reports.profitLossReport },
            { name: 'Certificate Report', href: links.reportLink + '/' + links.reports.certificateReport }
        ];

        this.actionLinks = [
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Appointment Card', href: links.appointmentCardLink + '/' + links.appointmentCard.List },
            { name: 'Submit Payment', href: links.submitPaymentLink + '/' + links.submitPayment.List },
            { name: 'My Yearly goal', href: links.myGoal }
        ];

        this.adminSettings = [
            { name: 'Item Management', href: '1' },
            { name: 'User Management', href: '2' },
            { name: 'Payments', href: '13' },
            { name: 'Survey Result', href: '3' },
            { name: 'Sales Management', href: '4' },
            { name: 'LGFG Certificates', href: '5' },
            { name: 'Our Goal', href: '6' },
            { name: 'Exchange Rate to CAD', href: '9' },
            { name: 'Provinces and Cities', href: '10' },
            { name: 'Office management', href: '11' },
            { name: 'EA/Clothier Deposit Email Notifications', href: '7' },
            { name: 'Alteration Category Management', href: '12' },
            { name: 'Reassign Report', href: links.reportLink + '/' + links.reports.reassignReport },
            { name: 'Package Discount Management', href: '17' }
        ];
        if (this.context.isTechSupport()) {
            this.adminSettings.push({ name: 'Reassign Rule Management', href: '14' });
            this.adminSettings.push({ name: 'Logs', href: '8' });
            this.adminSettings.push({ name: 'Emails', href: '15' });
            this.adminSettings.push({ name: 'Remove Discount', href: '16' });
            this.reportLinks.push({ name: 'QB Report', href: links.reportLink + '/' + links.reports.clothierQBReport });
            this.reportLinks.push({ name: 'New Clients Report', href: links.reportLink + '/' + links.reports.newClientReport });
        }
    }

    private setAccountingLinks(): void {
        this.reportLinks = [
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
            { name: 'My Items Report', href: links.reportLink + '/' + links.reports.itemsReport },
            { name: 'My Cashflow Report', href: links.reportLink + '/' + links.reports.cashflowReport },
            { name: 'TotalNCPBank Report', href: links.reportLink + '/' + links.reports.totalNCPBankReport },
            { name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport },
            { name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport },
            { name: 'My Payments Report', href: links.reportLink + '/' + links.reports.paymentReport },
            { name: 'Rep Overdue Report', href: links.reportLink + '/' + links.reports.overdueReport },
            { name: 'Management Report', href: links.reportLink + '/' + links.reports.managementReport },
            { name: 'Rep Overview Report', href: links.reportLink + '/' + links.reports.overviewReport },
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport },
            { name: 'Remake Item Report', href: links.reportLink + '/' + links.reports.remakeItemReport },
            { name: 'Profit Loss Report', href: links.reportLink + '/' + links.reports.profitLossReport },
            { name: 'Certificate Report', href: links.reportLink + '/' + links.reports.certificateReport }
        ];

        this.actionLinks = null;

        this.adminSettings = [
            { name: 'Item Management', href: '1' },
            { name: 'User Management', href: '2' },
            { name: 'Payments', href: '13' },
            { name: 'Sales Management', href: '4' },
            { name: 'LGFG Certificates', href: '5' },
            { name: 'Exchange Rate to CAD', href: '9' },
            { name: 'Provinces and Cities', href: '10' },
            { name: 'Office management', href: '11' },
            { name: 'Alteration Category Management', href: '12' },
            { name: 'Package Discount Management', href: '17' }
        ];
    }

    private setEaLinks(): void {
        this.reportLinks = this.reportLinks.concat([
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport }
        ]);

        this.actionLinks = this.actionLinks.concat([
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Appointment Card', href: links.appointmentCardLink + '/' + links.appointmentCard.List },
            { name: 'Submit Payment', href: links.submitPaymentLink + '/' + links.submitPayment.List },
        ]);
    }

    private setSmLinks(): void {
        this.reportLinks = this.reportLinks.concat([
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Booking Buddy Report', href: links.reportLink + '/' + links.reports.bookingBuddyReport }
        ]);

        this.actionLinks = this.actionLinks.concat([
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Appointment Card', href: links.appointmentCardLink + '/' + links.appointmentCard.List },
        ]);
    }

    private setRepLinks(): void {
        this.reportLinks = this.reportLinks.concat([
            { name: 'My Sales Report', href: links.reportLink + '/' + links.reports.mySalesReport },
            { name: 'My Items Report', href: links.reportLink + '/' + links.reports.itemsReport },
            { name: 'My Cashflow Report', href: links.reportLink + '/' + links.reports.cashflowReport },
            { name: 'TotalNCPBank Report', href: links.reportLink + '/' + links.reports.totalNCPBankReport },
            { name: 'My Alterations Report', href: links.reportLink + '/' + links.reports.alterationsReport },
            { name: 'My Expenses Report', href: links.reportLink + '/' + links.reports.expensesReport },
            { name: 'My Payments Report', href: links.reportLink + '/' + links.reports.paymentReport },
            { name: 'Rep Overdue Report', href: links.reportLink + '/' + links.reports.overdueReport },
            { name: 'Rep Overview Report', href: links.reportLink + '/' + links.reports.overviewReport },
            { name: 'Cashflow Trailers Report', href: links.reportLink + '/' + links.reports.cashflowTrailorsReport },
            { name: 'Top Clients Report', href: links.reportLink + '/' + links.reports.topClientReport },
        ]);

        this.actionLinks = this.actionLinks.concat([
            { name: 'DrivePipe', href: environment.pd },
            { name: 'Submit Payment', href: links.submitPaymentLink + '/' + links.submitPayment.List },
            { name: 'My Yearly goal', href: links.myGoal }
        ]);
    }

    private setSideBarState(): void {
        const array = window.location.href.split('/');
        const page = array[array.length - 1];
        const domen = array[array.length - 2];
        switch (domen) {
            case 'Report': this.tabInFocus = NavTabEnum.report;
                this.currentPage = this.reportLinks.find(x => x.href.includes(page))?.href;
                break;
            case 'SubmitPayment': this.tabInFocus = NavTabEnum.actions;
                this.currentPage = this.actionLinks.find(x => x.name == 'Submit Payment').href;
                break;
            case 'AppointmentCard': this.tabInFocus = NavTabEnum.actions;
                this.currentPage = this.actionLinks.find(x => x.name == 'Appointment Card').href;
                break;
            case 'documents': this.tabInFocus = NavTabEnum.documents; break;
            case '#': if (page == "MyGoal") {
                this.tabInFocus = NavTabEnum.actions;
                this.currentPage = this.actionLinks.find(x => x.name == 'My Yearly goal').href;
            } break;
            case 'AdminSettings': this.tabInFocus = NavTabEnum.administration;
                this.currentPage = this.adminSettings.find(x => x.href == page.split('Id=')[1])?.href
                break;
            default: this.tabInFocus = NavTabEnum.dashboard; break;
        }
    }
}
