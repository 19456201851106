export const saleTheoryQuestions = [
  {
    question: `You float a guy, and he says to you, on the spot "why are your suits better?"<br>Which is the best response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"We use the best materials in the world!"` },
      { number: "B", isCorrect: false, text: `"Our suits have a really good value proposition in terms of price"` },
      { number: "C", isCorrect: true, text: `"Let's connect when I'm by your office and you can take a look for yourself. Do you have your calendar handy on your phone?"` },
      { number: "D", isCorrect: false, text: `Open you suit and show him the details and the stitching and how high quality it is` }
    ],
    explanation: `<i>Use this as a "negotiation" to close him and then qualify him, instead of him qualifying you! Complying to a prospect's demand to "prove yourself" lowers your status and triggers sales resistance. Call out the elephant in the room that the question is a trap, close for a meeting and reverse the status by qualifying the prospect instead.</i>`
  },
  {
    question: `You are in a meeting and the prospect says to you, "why should I buy from you instead of the store down the street?"<br>Which is the best response?`,
    answers: [
      { number: "A", isCorrect: false, text: `"We have better quality"` },
      { number: "B", isCorrect: true, text: `"You're asking a suit salesman why you should buy from me. What do you think I'll say?... Instead of taking my word for it, you can try it for yourself and you'll know if we're going to keep doing business together or not"` },
      { number: "C", isCorrect: false, text: `"The store down the street doesn't have the selection I have, and with LGFG you get 600 fabric choices per season!"` },
      { number: "D", isCorrect: false, text: `Show him the QR code and how we track his wardrobe so with LGFG he always knows what he has, when he got it, and what he should be thinking about next!` }
    ],
    explanation: `<i>Complying to a prospect's demand to "prove yourself" lowers your status and triggers sales resistance. Call out the elephant in the room that the question is a trap and move forward logically, that the first order is a test of trust, which is a better test of trust than promises from a stranger.</i>`
  },
  {
    question: `Which of the following is the best photo to post on Linkedin <i>in order to generate more sales?</i>`,
    answers: [
      { number: "A", isCorrect: true, text: `A picture of you and your client next to each other, where you can see both faces clearly, but not both suits clearly` },
      { number: "B", isCorrect: false, text: `A picture of your client only showing his full body with the fit of the suit` },
      { number: "C", isCorrect: false, text: `A picture of our client's suit laid out beautifully on a table, showing its fine details` },
      { number: "D", isCorrect: false, text: `A picture of you in a client's office` }
    ],
    explanation: `Interest in your product is driven by the social proof a lot more than the product. Create the most amount of social proof by having pictures with your clients, which raise your social status and it will lead to more sales as people will already know who you are when you call and use said client's name now or in the future.`
  },
  {
    question: `What's the most effective way from the options below to sell on delivery?`,
    answers: [
      { number: "A", isCorrect: false, text: `Ask Questions about what the client wants next` },
      { number: "B", isCorrect: false, text: `Go through wardrobe card to find needs and gaps to fill` },
      { number: "C", isCorrect: true, text: `Lay out lots of fabrics so the client can impulse buy` },
      { number: "D", isCorrect: false, text: `Show pictures of different garments` }
    ],
    explanation: `The worst way to sell is "describing" something or explaining why someone should want something. Delivery sales are heavily driven by <i><b>impulse buying</b></i>. Show lots of fabrics so that something catches the client's eye. Follow our training at the "4 ideas" method for this, which allows you to set up showing 96 fabrics on each delivery.`
  },
  {
    question: `Who is most likely to give the most referrals?`,
    answers: [
      { number: "A", isCorrect: true, text: `Someone who was referred to you` },
      { number: "B", isCorrect: false, text: `Someone who was a cold call` },
      { number: "C", isCorrect: false, text: `Someone whom you floated` },
      { number: "D", isCorrect: false, text: `All the same` }
    ],
    explanation: `While all of the above will give referrals when asked correctly, the person who was referred is already comfortable with the process and believes it works- and this is how it works. Referrals not only book more often, they also refer better.`
  },
  {
    question: `You will have the biggest package size from:`,
    answers: [
      { number: "A", isCorrect: false, text: `Floats` },
      { number: "B", isCorrect: true, text: `Referrals` },
      { number: "C", isCorrect: false, text: `Cold Calls` },
      { number: "D", isCorrect: false, text: `SM Lead` }
    ],
    explanation: `While all of the above will give buy when presented correctly, the person who was referred is already will have more trust and naturally that leads to bigger sales.`
  },
  {
    question: `Who is most likely to cancel an order?`,
    answers: [
      { number: "A", isCorrect: false, text: `New client who spent $30,000 on their first order` },
      { number: "B", isCorrect: false, text: `Repeat client who spent $30,000 on their latest order` },
      { number: "C", isCorrect: true, text: `New client who spent $300 on their first order` },
      { number: "D", isCorrect: false, text: `Repeat client who spent $300 on their latest order` }
    ],
    explanation: `New clients who "just barely buy" aren't really that sold. New clients who buy very little are much more likely to cancel over someone who is really sold enough to spend serious cash (or a repeat client who already knows and trusts the company).`
  },
  {
    question: `You call a prospect who is excited to meet you and they start explaining everything they love and hate about suits like the fit, the construction type, the fabric weight etc. How should you interpret this?`,
    answers: [
      { number: "A", isCorrect: true, text: `Danger!! You should be very skeptical of this meeting` },
      { number: "B", isCorrect: false, text: `You know you can serve this person well because of our resounding fabric and style selection` },
      { number: "C", isCorrect: false, text: `Make sure to email them with different style options before the meeting` },
      { number: "D", isCorrect: false, text: `Make sure to prepare lots of fabrics for a big sale` }
    ],
    explanation: `The more "educated" a client thinks they already are, the more problems you're going to have.`
  },
  {
    question: `A prospect in a meeting says you're too expensive when you show the price options. What's the best reply?`,
    answers: [
      { number: "A", isCorrect: false, text: `"Compared to what?"` },
      { number: "B", isCorrect: false, text: `"But we have better quality than the competition"` },
      { number: "C", isCorrect: false, text: `"We have different price options so you can try what you like at a level you're comfortable with!"` },
      { number: "D", isCorrect: true, text: `"Should we continue, or would it be better to wrap this up?"` }
    ],
    explanation: `We're selling a luxury product. Ferrari's are expensive and people know that when they go to a dealership. If you're uncomfortable with Ferrari prices, consider going to the Toyota dealer. Best way to handle this objection at the meeting is simply to ask if we should wrap it up. And if they insist on continuing, simply ask what they may be shopping for. Don't give a "free tour" without a commitment back from the prospect as to why you're there (to get money).`
  },
  {
    question: `You have a truly great referral pitch but you're not getting ANY referrals.  What's the most likely problem?`,
    answers: [
      { number: "A", isCorrect: true, text: `Your meetings are too long` },
      { number: "B", isCorrect: false, text: `The prospects weren't referred themselves` },
      { number: "C", isCorrect: false, text: `People in your turf just don't give referrals` },
      { number: "D", isCorrect: false, text: `Prospects don't know you that well` }
    ],
    explanation: `If you're getting NO referrals it's because prospects are afraid you'll waste their friends' time. You need to recognise non-buying signals earlier so you know when to wrap up the meeting.`
  },
  {
    question: `Your package size is too small. What's the most likely cause?`,
    answers: [
      { number: "A", isCorrect: true, text: `You talk too much in the meetings` },
      { number: "B", isCorrect: false, text: `The prospects weren't referred` },
      { number: "C", isCorrect: false, text: `People in your turf just don't have money` },
      { number: "D", isCorrect: false, text: `Prospects don't know you that well` }
    ],
    explanation: `If your package size is low it's almost certainly because you over-sell and under-show. Stick to the script and don't give your sales monologues to prospects. Nobody wants to be sold.`
  },
  {
    question: `Which is the most important factor when people make a decision about which product to buy:`,
    answers: [
      { number: "A", isCorrect: true, text: `Evidence that other people have bought and like this product` },
      { number: "B", isCorrect: false, text: `The price to value proposition` },
      { number: "C", isCorrect: false, text: `The quality of the product` },
      { number: "D", isCorrect: false, text: `The customer service for the product` }
    ],
    explanation: `Social proof is the greatest driver for decision making.`
  }
];
