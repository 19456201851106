import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgClass } from '@angular/common';
import { QuizType } from '../core/enums/quiz-type';
import { QuizService } from '../core/service/quiz.service';
import { phoneQuestions } from './questions/phone-questions';
import { reschedulesAndCancelsQuestions } from './questions/reschedules-and-cancels-questions';
import { saleTheoryQuestions } from './questions/sale-theory-questions';
import { leadershipQuestions } from './questions/leadership-questions';
import { DateUtil } from '../core/utility/date.util';
import { buildingClientBaseQuestions } from './questions/building-client-base-questions';

interface Answer {
  number: string;
  text: string;
  isCorrect: boolean;
}

interface Question {
  question: string;
  answers: Answer[];
  explanation?: string;
}

interface QuizState {
  currentQuestionIndex: number;
  selectedAnswers: { [questionIndex: number]: Answer };
  score: number;
  incorrectAnswers: any[];
  timestamp: number;
}

const QUIZ_DATA = {
  [QuizType.Phone]: {
    questions: phoneQuestions,
    displayName: 'Phone Quiz',
  },
  [QuizType.ReschedulesCancels]: {
    questions: reschedulesAndCancelsQuestions,
    displayName: 'Reschedules and Cancels Quiz',
  },
  [QuizType.SaleTheory]: {
    questions: saleTheoryQuestions,
    displayName: 'Sale Theory Quiz',
  },
  [QuizType.Leadership]: {
    questions: leadershipQuestions,
    displayName: 'Leadership Quiz',
  },
  [QuizType.BuildingClientBase]: {
    questions: buildingClientBaseQuestions,
    displayName: 'Building A Client Base Quiz',
  },
};

@Component({
  selector: 'quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class QuizComponent implements OnInit {
  @ViewChild('nextBtn') nextBtnElement: ElementRef;

  quizType: QuizType;
  questions: Question[] = [];
  currentQuestionIndex = 0;
  selectedAnswer: Answer | null = null;
  selectedAnswers: { [questionIndex: number]: Answer } = {};
  score = 0;
  showResults = false;
  incorrectAnswers: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private quizService: QuizService,
    private ref: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.quizType = this.route.snapshot.params['type'] as QuizType;
    this.loadQuestions();
    await this.loadState();
    this.ref.detectChanges();
  }

  public selectAnswer(answer: Answer): void {
    if (this.selectedAnswer) {
      return;
    }

    this.selectedAnswer = answer;
    this.selectedAnswers[this.currentQuestionIndex] = answer;

    if (answer.isCorrect) {
      this.score++;
    } else {
      const currentQuestion = this.questions[this.currentQuestionIndex];
      this.incorrectAnswers.push({
        questionText: `${this.currentQuestionIndex + 1}. ${currentQuestion.question}`,
        answers: currentQuestion.answers,
        selectedAnswer: answer,
      });
    }
    this.scrollToBottom();
    this.saveState();
  }

  public async nextQuestion(): Promise<void> {
    this.selectedAnswer = null;
    this.currentQuestionIndex++;

    if (this.currentQuestionIndex >= this.questions.length) {
      this.showResults = true;
      const data = {
        quizName: this.getQuizDisplayName,
        totalQuestions: this.questions.length,
        incorrectAnswers: this.incorrectAnswers,
      };
      localStorage.removeItem(`${this.quizType}-quiz-state`);
      await this.quizService.sendQuizResultEmail(data);
    }
  }

  public getAnswerClass(answer: Answer): string {
    if (this.markAsCorrect(answer)) {
      return 'correct';
    } else if (this.markAsWrong(answer)) {
      return 'wrong';
    } else if (this.selectedAnswer && this.selectedAnswer !== answer) {
      return 'another';
    } else {
      return '';
    }
  }

  public markAsCorrect(answer: Answer): boolean {
    return answer.isCorrect && !!this.selectedAnswer;
  }

  public markAsWrong(answer: Answer): boolean {
    return !answer.isCorrect && this.selectedAnswer === answer;
  }

  public getQuestion(): string {
    return `${this.currentQuestionIndex + 1}. ${this.questions[this.currentQuestionIndex].question}`;
  }

  public getAnswer(answer: Answer): string {
    return `${answer.number}. ${answer.text}`;
  }

  public getExplanation(): string {
    return this.questions[this.currentQuestionIndex].explanation || '';
  }

  private saveState(): void {
    const state: QuizState = {
      currentQuestionIndex: this.currentQuestionIndex,
      selectedAnswers: this.selectedAnswers,
      score: this.score,
      incorrectAnswers: this.incorrectAnswers,
      timestamp: Date.now(),
    };
    localStorage.setItem(`${this.quizType}-quiz-state`, JSON.stringify(state));
  }

  public get getQuizDisplayName(): string {
    const quizData = QUIZ_DATA[this.quizType];
    return quizData ? quizData.displayName : 'Quiz';
  }

  public identify(index: number, item: Answer) {
    return item.number;
  }

  private async loadState(): Promise<void> {
    const stateStr = localStorage.getItem(`${this.quizType}-quiz-state`);

    if (stateStr) {
      const state: QuizState = JSON.parse(stateStr);
      const twoDaysAgo = DateUtil.minusDays(DateUtil.current, 2);

      if (state.timestamp >= twoDaysAgo.getTime()) {
        this.currentQuestionIndex = state.currentQuestionIndex;
        this.selectedAnswers = state.selectedAnswers;
        this.score = state.score;
        this.incorrectAnswers = state.incorrectAnswers;
        this.showResults = this.currentQuestionIndex >= this.questions.length;
        await this.nextQuestion();
      } else {
        localStorage.removeItem(`${this.quizType}-quiz-state`);
      }
    }
  }

  private loadQuestions(): void {
    const quizData = QUIZ_DATA[this.quizType];
    if (quizData) {
      this.questions = quizData.questions;
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.nextBtnElement?.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 200);
  }
}