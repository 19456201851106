<div class="quiz">
  <div class="quiz-container">
    <div class="progress-bar">
      <div class="progress" [style.width.%]="(currentQuestionIndex / questions.length) * 100"></div>
    </div>

    <div class="header">
      <div class="title">{{getQuizDisplayName}}</div>
    </div>

    @if(!showResults)
    {
    <ng-container>
      <div class="question" [innerHTML]="getQuestion()"></div>
      <div class="answer-container">
        @for (answer of questions[currentQuestionIndex].answers; track identify($index, answer)) {
        <div class="answer" [ngClass]="getAnswerClass(answer)" (click)="selectAnswer(answer)">
          <span [innerHTML]="getAnswer(answer)"></span>

          @if(markAsCorrect(answer) && selectedAnswer && selectedAnswer === answer)
          {
          <div class="answer-icon correct">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
            </svg>
          </div>
          }

          @if(markAsWrong(answer) && selectedAnswer && selectedAnswer === answer)
          {
          <div class="answer-icon wrong">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
              </path>
            </svg>
          </div>
          }
        </div>
        }
      </div>

      @if(selectedAnswer)
      {
      <div class="separator"></div>
      <ng-container>
        @if(getExplanation())
        {
        <div class="explanation-container">
          <div class="explanation-icon">
            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z">
              </path>
            </svg>
          </div>
          <div class="explanation" [innerHTML]="getExplanation()"></div>
        </div>
        }
      </ng-container>
      <div class="button-container">
        <button #nextBtn class="next-button" (click)="nextQuestion()">Next</button>
      </div>
      }
    </ng-container>
    }

    @if(showResults)
    {
    <div class="results">
      <div>Thank you! Your results:</div>
      <div>Total questions: {{ questions.length }}</div>
      <div>Correct answers: {{ score }}</div>
      <div>Incorrect answers: {{ questions.length - score }}</div>
      <div>Your score: {{(score / questions.length * 100).toFixed(2)}}%</div>
      <div>We’re building a 100M company by 2026. Let’s get back to work!</div>
    </div>
    }
  </div>
</div>